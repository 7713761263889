import { HomePage, TasksPage, ProfilePage, Foreword, Enquiry, ChangePassword } from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import { GroupASession1, GroupASession2, GroupASession3, GroupASession4, GroupASession5, GroupASession6 } from './pages';
import { GroupBSession1, GroupBSession2, GroupBSession3, GroupBSession4, GroupBSession5, GroupBSession6 } from './pages';
import { QuestionnaireT0, QuestionnaireT1, QuestionnaireT2, QuestionnaireT3 } from './pages'

const routes = [
    // {
    //     path: '/tasks',
    //     element: TasksPage,
    // },
    // {
    //     path: '/profile',
    //     element: ProfilePage
    // },
    {
        path: '/home',
        element: HomePage,
        allowedUserTypes: ["A", "B", "X", "Y"]

    },
    {
        path: '/foreword',
        element: Foreword,
        allowedUserTypes: ["A", "B", "X", "Y"]

    },
    {
        path: '/enquiry',
        element: Enquiry,
        allowedUserTypes: ["A", "B", "X", "Y"]

    },
    {
        path: '/questionnaireT0',
        element: QuestionnaireT0,
        allowedUserTypes: ["A", "B", "X", "Y"]

    },
    {
        path: '/questionnaireT1',
        element: QuestionnaireT1,
        allowedUserTypes: ["A", "B"]

    },
    {
        path: '/questionnaireT2',
        element: QuestionnaireT2,
        allowedUserTypes: ["A", "B"]

    },
    ,
    {
        path: '/questionnaireT3',
        element: QuestionnaireT3,
        allowedUserTypes: ["A", "B"]

    },
    {
        path: '/groupAsession1',
        element: GroupASession1,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupAsession2',
        element: GroupASession2,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupAsession3',
        element: GroupASession3,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupAsession4',
        element: GroupASession4,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupAsession5',
        element: GroupASession5,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupAsession6',
        element: GroupASession6,
        allowedUserTypes: ["A"]
    },
    {
        path: '/groupBsession1',
        element: GroupBSession1,
        allowedUserTypes: ["B"]
    },
    {
        path: '/groupBsession2',
        element: GroupBSession2,
        allowedUserTypes: ["B"]

    },
    {
        path: '/groupBsession3',
        element: GroupBSession3,
        allowedUserTypes: ["B"]
    },
    {
        path: '/groupBsession4',
        element: GroupBSession4,
        allowedUserTypes: ["B"]

    },
    {
        path: '/groupBsession5',
        element: GroupBSession5,
        allowedUserTypes: ["B"]

    },
    {
        path: '/groupBsession6',
        element: GroupBSession6,
        allowedUserTypes: ["B"]

    },
    {
        path: '/changepassword',
        element: ChangePassword,
        allowedUserTypes: ["A", "B", "X", "Y"]
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
