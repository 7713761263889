import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import './CreateAccountForm.scss';

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ phoneNumber: '', password: '' });

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { phoneNumber, password, confirmedPassword } = formData.current;
    setLoading(true);

    // const result = await createAccount(email, password);
    // const result = await axios.post(backendlink + '/user/signup',{
    //   phoneNumber: phoneNumber,
    //   password: password,
    //   confirmPassword: confirmedPassword
    // })
    // setLoading(false);
    // console.log("err", result.data.error)

    // if (result.data.error == 0) {
    //   navigate('/login');
    // } else {
    //   notify(result.data.error, 'error', 2000);
    // }
    try {
      const result = await axios.post(backendlink + '/user/signup',{
        phoneNumber: phoneNumber,
        password: password,
        confirmPassword: confirmedPassword
      })
  
      if (result.data.errCode == 0) {
        notify('Account successfully created', 'success', 2500);
        navigate('/login');
      } else {
        notify(result.data.errMessage, 'error', 2000);
      }
    } catch (error) {
      // console.log("hue", error);
      notify(error.response.data.errMessage, 'error', 2000);
    }

    setLoading(false);

  }, [navigate]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'phoneNumber'}
          editorType={'dxTextBox'}
          editorOptions={phoneNumberEditorOptions}
        >
          <RequiredRule message="Phone Number is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        {/* <Item>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>
          </div>
        </Item> */}
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Create a new account'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const phoneNumberEditorOptions = {  stylingMode: 'filled', placeholder: 'Phone Number', mode: 'tel' };
// const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
