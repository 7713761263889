import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { navigation as navigationB } from '../../app-navigationB';
import { navigation as navigationX } from '../../app-navigationX';
import { navigation as navigationY } from '../../app-navigationY';
import { navigation as navigationButton } from '../../app-side-button';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import { useAuth } from '../../contexts/auth';
import './SideNavigationMenu.scss';
import { useTranslation } from 'react-i18next';


import * as events from 'devextreme/events';

export default function SideNavigationMenu(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
    onBottomMenuReady
  } = props;

  const { isLarge } = useScreenSize();

  const { user, loading } = useAuth();

  const { t, i18n } = useTranslation();


  const { navigationData: { currentPath } } = useNavigation();

  function normalizePath () {
    
    if (user.group == "A"){
      return navigation.map((item, index) => (
        // { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path, disabled: user.progress / 2 < index && index !== 0
        { ...item, expanded: isLarge, text: t(item.text), path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path, disabled: user.progress - 1 < index && index !== 0 && index !== 10
      }
      ))
    }else if (user.group == "B"){
      return navigationB.map((item, index) => (
        // { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path , disabled: user.progress / 2 < index && index !== 0}
        { ...item, expanded: isLarge, text: t(item.text), path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path , disabled: user.progress - 1 < index && index !== 0 && index !== 10}
        ))
    }
    else if (user.group == "X"){
      return navigationX.map((item, index) => (
        // { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path , disabled: user.progress / 2 < index && index !== 0}
        { ...item, expanded: isLarge, text: t(item.text), path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
        ))
    }
    else if (user.group == "Y"){
      return navigationY.map((item, index) => (
        // { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path , disabled: user.progress / 2 < index && index !== 0}
        { ...item, expanded: isLarge, text: t(item.text), path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
        ))
    }
  }

  const items = useMemo(
    normalizePath,
    [user, i18n.language]
  );

  const items2 = navigationButton.map((item, index) => (
    { ...item, expanded: isLarge, text: t(item.text) }
    ))

    



  const treeViewRef = useRef(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e) => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;

    if (!treeView) {
      return;
    }
    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode, i18n.language, user]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      {user.group == "A" && <div style={{ position: 'absolute', bottom: 10, width: '100%', minWidth: 100 }}>
          <TreeView
              items={items2}
              keyExpr={'path'}
              selectionMode={'single'}
              focusStateEnabled={false}
              expandEvent={'click'}
              onItemClick={onBottomMenuReady}
              onContentReady={onMenuReady}
              width={'100%'}
            />
        </div>   }
      </div>
    </div>
  );
}
