export const navigation = [
  // {
  //   text: 'Screening Assessment',
  //   path: '/home',
  //   icon: 'home'
  // },
  {
    text: 'Foreword',
    path: '/foreword',
    icon: 'floppy'
  },
  // {
  //   text: 'Baseline T0 assessment',
  //   path: '/questionnaireT0',
  //   icon: 'floppy'
  // },
  {
    text: 'Lesson 1',
    path: '/groupBsession1',
    icon: 'floppy'
  },
  {
    text: 'Lesson 2',
    path: '/groupBsession2',
    icon: 'floppy'
  },
  {
    text: 'Lesson 3',
    path: '/groupBsession3',
    icon: 'floppy'
  },
  {
    text: 'Lesson 4',
    path: '/groupBsession4',
    icon: 'floppy'
  },
  {
    text: 'Lesson 5',
    path: '/groupBsession5',
    icon: 'floppy'
  },
  {
    text: 'Lesson 6',
    path: '/groupBsession6',
    icon: 'floppy'
  },
  {
    text: 'T1 assessment',
    path: '/questionnaireT1',
    icon: 'floppy'
  },
  {
    text: 'T2 assessment',
    path: '/questionnaireT2',
    icon: 'floppy'
  },
  {
    text: 'T3 assessment',
    path: '/questionnaireT3',
    icon: 'floppy'
  },
  {
    text: 'Enquiry',
    path: '/enquiry',
    icon: 'floppy'
  },
];
