import React, { useState, useEffect } from "react";
import { section1, section2, section3, section4, section5, section6, section7, section8, section9 } from './T1questions.js';
import { useTranslation } from 'react-i18next';

import Form, {
    Item,
    GroupItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,

} from 'devextreme-react/form';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { backendlink } from '../../config';

class ItemComponent extends React.PureComponent {
    render() {
        return <div style={{ fontSize: "20px", fontWeight: "bold" }}>{this.props.flag}</div>;
    }
}


export default function QuestionnaireT1() {


    const { user, updateUser } = useAuth();

    const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState({

    });
    const [SectionNumber, setSectionNumber] = useState(1);

    const { t } = useTranslation();

    const submitButtonAttributes = { class: 'submit-button' };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + '/init/getcontroldata',
                    {
                        progress: 8
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });
                let arr = response.data.data;
                const object = Object.fromEntries(arr.map(item => [Object.keys(item)[0], Object.values(item)[0]]));
                setQuestionnaireAnswer(object)
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        if (user.progress >= 8 && user.t1Status == "Incomplete") {
            fetchData();
        }
    }, []);

    const isFormFilled = () => {
        // Check if all form fields are filled
        return Object.values(QuestionnaireAnswer).every(val => val !== "");
    };

    const IncreaseSectionNumber = () => {
        setSectionNumber(SectionNumber + 1);

    }

    const DecreaseSectionNumber = () => {
        setSectionNumber(SectionNumber - 1);
    }

    const SaveQuestionnaire = async (e) => {


        const transformedState = {
            questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
                questionnumber,
                answer: text,
            }))
        }

        try {

            const result = await axios.post(backendlink + '/init/savecontroldata', {
                questionairelist: transformedState.questionairelist,
                progress: 8

            }, {
                headers: {
                    token: user.token
                }
            })

        } catch (error) {
            alert("Error with saving record")
        }

    }

    const SubmitQuestionnaire = async (e) => {

        e.preventDefault();

        if (SectionNumber < 7) {
            setSectionNumber(SectionNumber + 1);
        } else {
            const transformedState = {
                questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
                    questionnumber,
                    answer: text,
                }))
            };

            try {
                const result = await axios.post(backendlink + '/init/submitcontroldata', {
                    userToken: user.token,
                    questionairelist: transformedState.questionairelist,
                    progress: 8
                }, {
                    headers: {
                        token: user.token
                    }
                }).then((res) => {
                    updateUser(res.data.data)
                });

            } catch (error) {
                alert("Error with submitting record")
            }
        }

    }

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>
                {user.t1Status == "Incomplete" &&
                    <form onSubmit={SubmitQuestionnaire}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                // Keeps form from submiting on hitting enter
                                e.preventDefault();
                            }
                        }}
                    >
                        <Form className="radiobuttons" formData={QuestionnaireAnswer} showValidationSummary={true}>

                            {SectionNumber === 1 &&
                                <GroupItem caption={t('Section 1')}>
                                    {section1.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 1`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }
                            {SectionNumber == 2 && <GroupItem caption={t('Section 2')}>
                                {section2.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            <RequiredRule message={`Please answer question ${question.id} in section 2`} />
                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>
                                    )
                                ))}
                            </GroupItem>}
                            {SectionNumber === 3 &&
                                <GroupItem caption={t('Section 3')}>
                                    {section3.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 3`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }
                            {SectionNumber === 4 &&
                                <GroupItem caption={t('Section 4')}>
                                    {section4.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 4`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }
                            {SectionNumber == 5 && <GroupItem caption={t('Section 5')}>
                                {section9.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            <RequiredRule message={`Please answer question ${question.id} in section 5`} />

                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>)
                                ))}
                            </GroupItem>}
                            {SectionNumber == 6 && user.group == "A" && <GroupItem caption={t('Section 6')}>
                                {section5.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}
                                        >
                                            {question.id != "C1b" && question.id != "C1c" && question.id != "C2b" && question.id != "C2c" && question.id != "C3b" && question.id != "C3c" && question.id != "C4b" &&
                                                question.id != "C4c" && question.id != "C5b" && question.id != "C5c" && question.id != "C6b" && question.id != "C6c" && question.id != "C7b" && question.id != "C7c" &&
                                                question.id != "C8b" && question.id != "C8c" && question.id != "C9b" && question.id != "C9c" && question.id != "C10b" && question.id != "C10c" &&
                                                question.id != "C11b" && question.id != "C11c" && question.id != "C12b" && question.id != "C12c" && question.id != "C13b" && question.id != "C13c" &&
                                                question.id != "C14b" && question.id != "C14c" && question.id != "C15b" && question.id != "C15c" &&
                                            <RequiredRule message={`Please answer question ${question.id} in section 6`} />}
                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>
                                    )
                                ))}
                                {section6.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}
                                        >
                                            <RequiredRule message={`Please answer question ${question.id} in section 6`} />
                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>
                                    )
                                ))}
                            </GroupItem>
                            }
                            {SectionNumber == 6 && user.group == "B" && <GroupItem caption={t('Section 6')}>
                                {section7.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}
                                        >
                                            <RequiredRule message={`Please answer question ${question.id} in section 6`} />
                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>
                                    )
                                ))}
                            </GroupItem>}

                            {/* {SectionNumber == 5 && <GroupItem caption='Section 5'>
                {section5.map((question, index) => (
                <Item key={question.id}
                    editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                    editorOptions={question.type === "dxRadioGroup" ? {items: question.options, layout:"horizontal", valueExpr: 'score'} : {}}
                    dataField={question.id}>
                    <RequiredRule message={`Please answer question ${index+1} in section 5`} />

                    <Label text={question.text}/>
                </Item>
                ))}
            </GroupItem>}
            {SectionNumber == 6 && <GroupItem caption='Section 6'>
                {section6.map((question, index) => (
                <Item key={question.id}
                    editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                    editorOptions={question.type === "dxRadioGroup" ? {items: question.options, layout:"horizontal", valueExpr: 'score'} : {}}
                    dataField={question.id}>
                    <RequiredRule message={`Please answer question ${index+1} in section 6`} />

                    <Label text={question.text}/>
                </Item>
                ))}
            </GroupItem>}
            {SectionNumber == 7 && <GroupItem caption='Section 7'>
                {section7.map((question, index) => (
                <Item key={question.id}
                    editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                    editorOptions={question.type === "dxRadioGroup" ? {items: question.options, layout:"horizontal", valueExpr: 'score'} : {}}
                    dataField={question.id}>
                    <RequiredRule message={`Please answer question ${index+1} in section 7`} />

                    <Label text={question.text}/>
                </Item>
                ))} 
            </GroupItem>} */}
                            {SectionNumber == 7 && <GroupItem caption={t('Section 7')}>
                                <Item>
                                    <ItemComponent flag={t("Demographics Information")} />
                                </Item>
                                {section8.map((question, index) => (
                                    <Item key={question.id}
                                        editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                        editorOptions={question.type === "dxRadioGroup" ? {
                                            items: question.options.map(option => ({
                                                ...option,
                                                text: t(option.text), // Translate each option text
                                            })), layout: "horizontal", valueExpr: 'score'
                                        } : {}}
                                        dataField={question.id}>
                                        {/* <RequiredRule message={`Please answer question ${question.id} in section 6`} /> */}
                                        {question.id != "D1b" && question.id != "D2b" && question.id != "D3b" && question.id != "D5b" && question.id != "D5b1" && question.id != "D6a" && question.id != "D6b" &&
                                            question.id != "D5a1" && question.id != "D6c" && question.id != "D6d" && question.id != "D6c1" && question.id != "D7b" && question.id != "D7c" &&
                                            <RequiredRule message={`Please answer question ${question.id} in section 7`} />}
                                        <Label text={question.id + ". " + t(question.text)} />
                                    </Item>
                                ))}
                            </GroupItem>}
                            <GroupItem colCount={3}>
                                <ButtonItem horizontalAlignment='left'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        disabled={SectionNumber == 1}
                                        width={"50%"}
                                        onClick={DecreaseSectionNumber}
                                    >
                                        {t("Previous Page")}
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem horizontalAlignment='center'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        width={"50%"}
                                        onClick={SaveQuestionnaire}
                                    >
                                        {t("Save")}
                                    </ButtonOptions>
                                </ButtonItem>
                                {SectionNumber == 7 && <ButtonItem horizontalAlignment='right'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        width={"50%"}
                                        useSubmitBehavior={true}
                                    // onClick={SubmitQuestionnaire}
                                    >
                                        {t("Submit")}
                                    </ButtonOptions>
                                </ButtonItem>}
                                {SectionNumber != 7 && <ButtonItem horizontalAlignment='right'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        disabled={SectionNumber == 7}
                                        width={"50%"}
                                        useSubmitBehavior={true}
                                    // onClick={IncreaseSectionNumber}
                                    >
                                        {t("Next Page")}
                                    </ButtonOptions>
                                </ButtonItem>}
                            </GroupItem>
                        </Form>
                    </form>
                }
                {user.t1Status != "Incomplete" && <h1>{t("T1 assessment completed, please complete T2 assessment after 3 months")}</h1>
                }
            </div>
        </React.Fragment>
    )
}

