import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import axios from "axios";
import { backendlink } from '../config';
import Cookies from 'js-cookie';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
      setLoading(false);
  }, []);

  async function updateUserProgress(userprogress){
    await setUser({...user, progress: userprogress});
    const updatedUser = {...user, progress: userprogress};
    Cookies.set("user", JSON.stringify(updatedUser), {expires: 7});
  }

  async function updateUserConsent(consent){
    await setUser({...user, consent: consent});
    const updatedUser = {...user, consent: consent};
    Cookies.set("user", JSON.stringify(consent), {expires: 7});
  }

  async function updateUser(updateduser){
    await setUser(updateduser);
    // const updatedUser = {...user, consent: consent};
    Cookies.set("user", JSON.stringify(updateduser), {expires: 7});
  }


  const signIn = useCallback(async (phoneNumber, password) => {
    try {
      const response = await axios.post(backendlink + '/user/signin', {
        phoneNumber: phoneNumber,
        password: password
      });
      if (response.data.errCode == 0) {
        setUser(response.data);
        Cookies.set("user", JSON.stringify(response.data), {expires: 7});
      }
      return response.data;
    } catch (error) {
      if (error.response){
        return error.response.data;
      }
      else{
        return error.data;
      }
    }
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    Cookies.remove("user");
  }, []);


  return (
    <AuthContext.Provider value={{ user, setUser, signIn, signOut, updateUserProgress, updateUserConsent, updateUser, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
