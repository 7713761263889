import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useTranslation } from 'react-i18next';
import { useAuth } from './contexts/auth';
import React, { useState, useEffect } from 'react';
import './index.css';


export default function Content() {

  const { t } = useTranslation();

  const [currRoutes, setCurrRoutes] = useState(routes)
  const { user } = useAuth();

  useEffect(() => {
    const newRoutes =  routes.filter(r => r.allowedUserTypes.includes(user.group))
    setCurrRoutes(newRoutes)
  }, [user])
  
  return (
    <SideNavBarLayout title={t(appInfo.title)}>
      <Routes>
        {currRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/foreword' />}
        />
      </Routes>
      <Footer>
        Empowered by Laboratory of Data Discovery for Health (D²4H)
      </Footer>
    </SideNavBarLayout>
  );
}

