import React from "react";
import { ChangePasswordForm } from "../../components";

export default function ChangePassword() {

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>
                <h2> Change Password</h2>
                <ChangePasswordForm />
            </div>
        </React.Fragment>
    )
}
