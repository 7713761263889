import React, { useState, useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';

export default function Lesson6Exercise(){

  const { user } = useAuth();

  const { t } = useTranslation();


  const [tableData, setTableData] = useState([
    { question: 'My pre-specified daily worry period is (try to find a convenient time, not less than 2 hours before bed)', column1: ''},
    { question: 'My worry period location is (try to find a distinctive spot that is comfortable and without distractions)' ,column1: ''},
    { question: 'My worry period is (min): (not more than 20 minutes)', column1: ''},
  ]);

  const [table1Data, setTable1Data] = useState([
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
    { date: 'Date', column1: ''},
    { date: 'Worries' ,column1: ''},
  ]);

  const [table2Data, setTable2Data] = useState([
    { topic: 'Date', column1: '', column2: '', column3: '', column4:'', column5:'', column6:'', column7: ''},
    { topic: 'Were you able to deal with any worries in the worry period?' ,column1: '', column2: '', column3: '', column4:'', column5:'', column6:'', column7: ''},
    { topic: 'Outcome (e.g. Used the time for something important, made an action plan for my worries, worries decreased, others) ', column1: '', column2: '', column3: '', column4:'', column5:'', column6:'', column7: ''},
  ]);

  const table2Headers = [
    '',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday', 
    'Saturday',
    'Sunday'
  ];

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.post(backendlink + '/exercise/getexercise',
          {
            lessonNumber: 6,
            exerciseNumber: 1
          },  
          {
              headers: {
                token: user.token
              }        
          });
          if (response.data.errMessage !== "No exercise found"){
            const data = JSON.parse(response.data.content);

            var updatedTableData = tableData.map((item, index) => ({
              ...item,
              column1: data.tableData[index]
            }));

            var updatedTable1Data = table1Data.map((item, index) => ({
              ...item,
              column1: data.table1Data[index]
            }));

            const updatedTable2Data = table2Data.map((item, index) => ({
              ...item,
              ...data.table2Data[index],
            }));

            setTableData(updatedTableData);
            setTable1Data(updatedTable1Data);
            setTable2Data(updatedTable2Data);
          }

        } catch (error) {
          console.log(error);
          alert(error);
        }
      };
      
      fetchData();
  }, []);

  const handleInputChange = (event, rowIndex, columnName) => {
    const newData = [...tableData];
    newData[rowIndex][columnName] = event.target.value;
    setTableData(newData);
  };

  const handleInputChange1 = (event, rowIndex, columnName) => {
    const newData = [...table1Data];
    newData[rowIndex][columnName] = event.target.value;
    setTable1Data(newData);
  };

  const handleInputChange2 = (event, rowIndex, columnName) => {
    const newData = [...table2Data];
    newData[rowIndex][columnName] = event.target.value;
    setTable2Data(newData);
  };

  const handleSubmission = async () => {

    const tableDataColumns = tableData.map(item => item.column1);
    const table1DataColumns = table1Data.map(item => item.column1);
    const table2DataColumns = table2Data.map(({ topic, ...rest }) => rest);


    const data = {
      tableData: tableDataColumns,
      table1Data: table1DataColumns,
      table2Data: table2DataColumns
    };
  
    const jsonData = JSON.stringify(data);
  
    try {
      const response = await axios.post(backendlink + '/exercise/submitexercise',
      {
        lessonNumber: 6,
        exerciseNumber: 1, 
        content: jsonData
      },  
      {
          headers: {
            token: user.token
          }        
      });
      
    } catch (error) {
      console.log(error);
      alert(error);
    }  };

  return (
    <div>
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: 20}}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.question}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(row.question)}</td>
              <td>
                <input
                  type="text"
                  value={t(row.column1)}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column1')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h5><strong>{t("My worries: Please write down the daily worries you have outside of your worry time; and then worry about these during your worry period.")} </strong></h5>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <tbody>
          {table1Data.map((row, rowIndex) => (
            <tr key={row.date}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(row.date)}</td>
              <td>
                <input
                  type="text"
                  value={row.column1}
                  onChange={(event) =>
                    handleInputChange1(event, rowIndex, 'column1')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h5><strong>{t("When you have completed your worry period, please tick the appropriate box and make some comments in the table below:")} </strong></h5>
      <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: 20 }}>
        <thead>
          <tr>
            {table2Headers.map((header) => (
              <th key={header} style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table2Data.map((row, rowIndex) => (
            <tr key={row.topic}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(row.topic)}</td>
              <td>
                <input
                  type="text"
                  value={row.column1}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column1')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column2}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column2')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column3}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column3')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column4}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column4')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column5}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column5')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column6}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column6')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column7}
                  onChange={(event) =>
                    handleInputChange2(event, rowIndex, 'column7')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button onClick={handleConsoleLog}>Console Log Table Data</button> */}
      <Button text={t("Submit")} type="default" onClick={handleSubmission}/>

    </div>
  );
};