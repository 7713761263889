import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react/button';
import { AuthProvider } from '../../contexts/auth';
import axios from 'axios';
import './enquiry.css';
import { useTranslation } from 'react-i18next';
import { StartingQuestionnaire } from '../../components';
import { backendlink } from '../../config';
import ConsentForm from '../../components/consent-form/ConsentForm';
import { Toast } from 'devextreme-react/toast';


export default function Enquiry() {

    const { user } = useAuth();

    const { t } = useTranslation();

    const [value, setValue] = useState("");

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: t('We have recieved your help request'),
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const handleButtonClick = async () => {
        try {
            const result = await axios.post(backendlink + '/chat/sendmessage', {
                userToken: user.token,
                text: value
            }, {
                headers: {
                    token: user.token
                }
            })
                .then((res) => {
                    if (res.data.errCode == 0){
                        setValue("");
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'info',
                            message: 'We have recieved your help request',
                          });
                    }else{
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: res.data.errMessage,
                          });
                    }

                });
        } catch (error) {
            // console.log("error", error)
            alert(error);
        }
    };

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>
                <h5>{t("If you need help, please type in the textbox below. One of our staff will contact you in the future.")} </h5>
                <div className='appfooter'>
                    <input type="text" placeholder={t("Message us for any problems")} maxLength={100} value={value} onInput={handleInput} style={{
                        width: "900px",
                        height: "40px",
                        marginRight: "20px",
                        display: 'inline-block',
                        border: '1px solid #ccc', // Add a border with a solid color
                        // border-radius: '4px', // Add rounded corners to the border
                        padding: '5px', // Add padding to the input
                    }}
                    />
                    <Button text={t("Send")} type="default" onClick={handleButtonClick} style={{ display: 'inline-block' }} />
                    <div style={{ verticalAlign: 'top', marginTop: '5px' }}>
                        {100 - value.length} {t("characters remaining")}
                    </div>
                </div>
                <Toast
                    visible={toastConfig.isVisible}
                    message={toastConfig.message}
                    type={toastConfig.type}
                    onHiding={onHiding}
                    displayTime={2000}
                />
            </div>
        </React.Fragment>
    )
}