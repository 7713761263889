import React, { useState, useEffect } from 'react';

// import 'devextreme/dist/css/dx.light.css';
// import './session1.scss';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useAuth } from '../../contexts/auth';
import YouTube from 'react-youtube';
import axios from 'axios';
import { backendlink } from '../../config';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';


export default function Session6(){
  const [videoId, setVideoId] = useState('A90HS0kbKyE');

  const { user, updateUserProgress } = useAuth();

  const navigate = useNavigate();
  const { t } = useTranslation();


  const navigateToT1Questionnaire = () => {
    navigate('/questionnaireT1');
  };

  async function onVideoEnd() {
    try {
      const result = await axios.post(backendlink + '/lesson/completedvideo', {
        lessonNumber: 7
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode == 0) {
        updateUserProgress(result.data.progress);
        navigateToT1Questionnaire();
      }
    } catch (error) {
      console.log(error);
    }

  }

  const opts = {
    height: '390',
    width: '640',
    // playerVars: {
    //   autoplay: 1,
    // },
  };

    return(
        <React.Fragment>
        <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
        <Item title={t("Video")} icon="floppy">
            {videoId && (
              <ReactPlayer
              url={`https://www.youtube.com/watch?v=${videoId}`}
                opts={opts}
                controls={true}
                onEnded={onVideoEnd}
                />
            )}
            </Item>      
        </TabPanel>
        </React.Fragment>
    )
}