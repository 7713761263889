import React, { useState, useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import './Lesson4Exercise.scss';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';

export default function Lesson4Exercise(){

  const { user } = useAuth();

  const { t } = useTranslation();


  const [tableData, setTableData] = useState([
    { day: 'Monday', column1: '', column2: "", column3: "", column4: ""},
    { day: 'Tuesday',column1: '', column2: "", column3: "", column4: ""},
    { day: 'Wednesday', column1: '', column2: "", column3: "", column4: ""},
    { day: 'Thursday', column1: '', column2: "", column3: "", column4: ""},
    { day: 'Friday', column1: '', column2: "", column3: "", column4: ""},
    { day: 'Saturday', column1: '', column2: "", column3: "", column4: ""},
    { day: 'Sunday', column1: '', column2: "", column3: "", column4: ""},
  ]);

  const tableHeaders = [
    'Date',
    'Name of the metaphor / technique',
    'Practiced? Yes / No',
    'Were you able to notice and observe your thoughts without engaging in it? Yes / No / A Little',
    'Benefits and/or difficulties',
  ];

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.post(backendlink + '/exercise/getexercise',
          {
            lessonNumber: 4,
            exerciseNumber: 1
          },  
          {
              headers: {
                token: user.token
              }        
          });
          if (response.data.errMessage !== "No exercise found"){
            const data = JSON.parse(response.data.content);
           const updatedTableData = tableData.map((item, index) => ({
            ...item,
            ...data.tableData[index],
          }));
            setTableData(updatedTableData);
          }

        } catch (error) {
          console.log(error);
          alert(error);
        }
      };
      
      fetchData();
  }, []);

  const handleInputChange = (event, rowIndex, columnName) => {
    const newData = [...tableData];
    newData[rowIndex][columnName] = event.target.value;
    setTableData(newData);
  };

  const handleSubmission = async () => {

    const newDataArray = tableData.map(({ day, ...rest }) => rest);

    const data = {
      tableData: newDataArray
    };
  
    const jsonData = JSON.stringify(data);
  
    try {
      const response = await axios.post(backendlink + '/exercise/submitexercise',
      {
        lessonNumber: 4,
        exerciseNumber: 1, 
        content: jsonData
      },  
      {
          headers: {
            token: user.token
          }        
      });
      
    } catch (error) {
      console.log(error);
      alert(error);
    }  };

  return (
    <div>
      <table style={{ borderCollapse: 'collapse', width: '100%' ,marginTop: "20px", marginBottom: "20px" }}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header} style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.day}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(row.day)}</td>
              <td>
                <input
                  type="text"
                  value={row.column1}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column1')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column2}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column2')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column3}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column3')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.column4}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column4')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button onClick={handleConsoleLog}>Console Log Table Data</button> */}
      <Button text={t("Submit")} type="default" onClick={handleSubmission}/>

    </div>
  );
};