import React, { useState, useEffect } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
// import 'devextreme/dist/css/dx.light.css';
// import './session1.scss';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useAuth } from '../../contexts/auth';
import YouTube from 'react-youtube';
import axios from 'axios';
import { backendlink } from '../../config';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react';
import { Toast } from 'devextreme-react/toast';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';


export default function Session3(){

  const [videoId, setVideoId] = useState('eW85TLg5P6k');


  const { user, updateUserProgress } = useAuth();
  const [popupvisible, setpopupvisible] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: 'We have recieved your help request',
  });

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  const navigate = useNavigate();

  const { t } = useTranslation();


  const navigateToGroupBsession4 = () => {
    navigate('/groupBsession4');
  };

  useEffect(()=>{
    if(user.progress == 4){
      setpopupvisible(true);
    }
  },[])

  async function onVideoEnd() {
    try {
      const result = await axios.post(backendlink + '/lesson/completedvideo', {
        lessonNumber: 4
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode == 0) {
        updateUserProgress(result.data.progress);
        navigateToGroupBsession4();
      }
    } catch (error) {
      console.log(error);
    }

  }

  const opts = {
    height: '390',
    width: '640',
    // playerVars: {
    //   autoplay: 1,
    // },
  };

  const emailButtonOptions = {
      icon: 'email',
      text: t('Help'),
      onClick: handleSendApiRequest
    };
    const closeButtonOptions = {
      text: t('Close'),
      onClick: handleClosePopup
    };

  async function handleSendApiRequest() {
    try{
      const result = await axios.post(backendlink + '/chat/sendmessage',{
        userToken: user.token,
        text: "Help Requested by " + user.patientID
      },{
      headers: {
          token: user.token
      }
      })
      .then((res)=>{
        setpopupvisible(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'info',
          message: 'We have recieved your help request',
        });
      });
    }catch(error){
        console.log("error")
        alert("There was an error sending help, please try again.")
    }
  }

  function handleClosePopup() {
    setpopupvisible(false);
  }

    return(
        <React.Fragment>
        <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
        <Item title={t("Video")} icon="floppy">
            {videoId && (
              <ReactPlayer
              url={`https://www.youtube.com/watch?v=${videoId}`}
                opts={opts}
                controls={true}
                onEnded={onVideoEnd}
                />
            )}
            </Item>          
        </TabPanel>
        <Popup
          visible={popupvisible}
          dragEnabled={true}
          hideOnOutsideClick={true}
          // content={popupContent}
          width={500}
          height={380}
          title='Need Help?'
        >
      <div>
        <h6>{t("You have finished half of the course now, do you need any help? Click on the help button and one of our staff will contact you at a later date.")} </h6>
      </div>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={emailButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />

        </Popup>
        <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
        </React.Fragment>
    )
}