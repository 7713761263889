import React, {useCallback} from 'react';
import './home.scss';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react/button';
import { AuthProvider } from '../../contexts/auth';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { StartingQuestionnaire } from '../../components';
import { backendlink } from '../../config';
import ConsentForm from '../../components/consent-form/ConsentForm';

export default function Home() {

  const { user, updateUserProgress } = useAuth();

  const { t } = useTranslation();

  const NormalscoreStyle = {
    display: 'inline-block',
    width: '50px',  // Adjust the size as needed
    height: '50px', // This ensures it's a square
    backgroundColor: '#FCD12A',
    color: 'white',
    borderRadius: '5px',
    textAlign: 'center',
    lineHeight: '50px', // Center the text vertically
  };
  const AbovescoreStyle = {
    display: 'inline-block',
    width: '50px',  // Adjust the size as needed
    height: '50px', // This ensures it's a square
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '5px',
    textAlign: 'center',
    lineHeight: '50px', // Center the text vertically
  };
  const BelowscoreStyle = {
    display: 'inline-block',
    width: '50px',  // Adjust the size as needed
    height: '50px', // This ensures it's a square
    backgroundColor: '#39e75f',
    color: 'white',
    borderRadius: '5px',
    textAlign: 'center',
    lineHeight: '50px', // Center the text vertically
  };


  return (
    <React.Fragment>
      {/* {user.progress == 0 && <h2 className={'content-block'}> {t("Consent Form")}</h2>}
      {user.progress > 0 && <h2 className={'content-block'}> {t("Consent Form")}</h2>} */}
      <div className={'content-block dx-card responsive-paddings'}>
        {
          user.progress == 0 &&  
            user.consent == 0 && 
              <div>
                {/* <h2 > {t("Consent Form")}</h2> */}
              < ConsentForm />
            </div>
        }
       {
          user.progress == 0 &&  
            user.consent == 1 && 
              <div>
                <h2 > {t("Screening Assessment")}</h2>
              < StartingQuestionnaire />
            </div>
        }
        { 
          user.progress > 0 && user.group !== 'X' && 
        <div>  
          <h2> {t('Your fear of cancer recurrence score is')} <span style={NormalscoreStyle}>{user.questionnaireScore}</span></h2>
          <h5> {t('The screening result shows that you may currently experience a subclinical level of fear of cancer recurrence, which requires further support. Perhaps this eConquerFear webpage can help. This webpage consists of six online modules, each teaching a specific topic. You are highly encouraged to complete each module weekly and practice the skills in your daily life.')}</h5>
          <h5> {t("If you have any questions or need further assistance at any time during the program, please don't hesitate to reach out to our support team by leaving us a message in the message box or calling 3917 9606.")}</h5>
        </div>
        }
        { user.progress == 1 && user.group == 'X' && user.questionnaireScore > 21 && 
        
        <div>  
        <h2> {t('Your fear of cancer recurrence score is')} <span style={AbovescoreStyle}>{user.questionnaireScore}</span></h2>
        <h5> {t('The screening result shows that you may currently experience a clinical level of fear of cancer recurrence, which requires professional support. The counselling team from the HKU Jockey Club Institute of Cancer Care (JCICC) will contact you within two weeks to schedule an appointment for further evaluation and consultation. They will provide you with personalized guidance and strategies to help manage your fear.')}</h5>
        <h5> {t("Or You can call JCICC at 3917 9606 immediately or Contact Us for professional consultation.")}</h5>
      </div>
        
        }

        { user.progress == 1 && user.group == 'X' && user.questionnaireScore < 17 && user.questionnaireScore >= 0 && 
        
        <div>  
        <h2> {t('Your fear of cancer recurrence score is')} <span style={BelowscoreStyle}>{user.questionnaireScore}</span></h2>
        <h5> {t('The screening result shows that you may currently experience a mild level of fear of cancer recurrence, which is a normal and realistic concern. You are doing great! Keep it up!')}</h5>
        <h5> {t("If you would like to receive more information about managing fear of cancer recurrence, please call the HKU Jockey Club Institute of Cancer Care (JCICC) at 3917 9606 or visit our webpage: https://jcicc.med.hku.hk/")}</h5>
      </div>
        
        }
      </div>
    </React.Fragment>
)}
