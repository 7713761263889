import React, { useState, useEffect } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

// import './session1.scss';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useAuth } from '../../contexts/auth';
import YouTube from 'react-youtube';
import { Lesson3Exercise } from '../../components';
import { Button } from 'devextreme-react';
import axios from 'axios';
import { backendlink } from '../../config';
import { Navigate, useNavigate } from 'react-router-dom';
import { Toast } from 'devextreme-react/toast';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';

import Form, {
  Item as FormItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

export default function Session3(){

  const [popupvisible, setpopupvisible] = useState(false)

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: 'We have recieved your help request',
  });

  const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState({ "Explore meaning and potential impact of relevant past life experience on your level of fear of cancer recurrence": false, "Practice of attentional training techniques": false });

  const submitButtonAttributes = { class: 'submit-button' };

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  const { user, updateUserProgress } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();


  const navigateToGroupAsession4 = () => {
    navigate('/groupAsession4');
  };

  useEffect(()=>{
    if(user.progress == 4){
      setpopupvisible(true);
    }
    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/videoexercise/getexercise',
          {
            videoexercisenumber: 3,
          },
          {
            headers: {
              token: user.token
            }
          });

        if (response.data.errMessage !== "No video exercise found") {
          let originalFormat = response.data.data.reduce((obj, item) => {
            obj[item.questionnumber] = item.answer.toLowerCase() === 'true';;
            return obj;
          }, {});
          setQuestionnaireAnswer(originalFormat);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    fetchData();
  },[])

  const submitQuestionnaire = async (e) => {

    e.preventDefault();
    try {

      let transformedAnswer = Object.entries(QuestionnaireAnswer).map(([questionnumber, answer]) => ({ questionnumber, answer: String(answer) }));


      const result = await axios.post(backendlink + '/videoexercise/submitexercise', {
        videoexercisenumber: 3,
        questionlist: transformedAnswer
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode === 0) {
        // updateUserProgress(result.data.progress);
        notify(t("Exercise submitted successfully"));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  async function onVideoEnd() {
    try {
      const result = await axios.post(backendlink + '/lesson/completedvideo', {
        lessonNumber: 4
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode == 0) {
        updateUserProgress(result.data.progress);
        navigateToGroupAsession4();
      }
    } catch (error) {
      console.log(error);
    }

  }

  const opts = {
    height: '390',
    width: '640',
    // playerVars: {
    //   autoplay: 1,
    // },
  };

  const emailButtonOptions = {
    icon: 'email',
    text: t('Help'),
    onClick: handleSendApiRequest
  };
  const closeButtonOptions = {
    text: t('Close'),
    onClick: handleClosePopup
  };

  async function handleSendApiRequest() {
    try{
      const result = await axios.post(backendlink + '/chat/sendmessage',{
        userToken: user.token,
        text: "Help Requested by " + user.patientID
      },{
      headers: {
          token: user.token
      }
      })
      .then((res)=>{
        setpopupvisible(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'info',
          message: t('We have recieved your help request'),
        });
      });
    }catch(error){
        console.log("error")
        alert("There was an error sending help, please try again.")
    }
  }

  function handleClosePopup() {
    setpopupvisible(false);
    
  }

  // const renderContent = () =>  {
  //   return(
  //     <div>
  //       <h6>{t("You have finished half of the course now, do you need any help? Click on the help button and one of our staff will contact you at a later date.")} </h6>
  //       <Button text={t("Help")} onClick={handleSendApiRequest} />
  //       <Button text={t("Close")} onClick={handleClosePopup} />
  //     </div>
  //   )
  //   }

    const [videoId, setVideoId] = useState('QBdpCfsoPrY');


    return(
        <React.Fragment>
        <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
        <Item title={t("Video")} icon="floppy">
              {videoId && (
              <ReactPlayer
              url={`https://www.youtube.com/watch?v=${videoId}`}
                opts={opts}
                controls={true}

                onEnded={onVideoEnd}
                />
            )}
                      <form onSubmit={submitQuestionnaire}>

<Form className="radiobuttons responsive-paddings" formData={QuestionnaireAnswer} showValidationSummary={true}
>
  <GroupItem caption={t('Please tick the following information/tips that you learned from the video.')}>
    <FormItem editorType="dxCheckBox"
      dataField="Explore meaning and potential impact of relevant past life experience on your level of fear of cancer recurrence">
      <Label text={t("Explore meaning and potential impact of relevant past life experience on your level of fear of cancer recurrence")} />
    </FormItem>
    <FormItem editorType="dxCheckBox"
      dataField='Practice of attentional training techniques'>
      <Label text={t('Practice of attentional training techniques')} />
    </FormItem>
  </GroupItem>
  <ButtonItem horizontalAlignment='center'>
    <ButtonOptions
      elementAttr={submitButtonAttributes}
      type={'default'}
      useSubmitBehavior={true}
      width={"20%"}
    >
      {t("Submit")}
    </ButtonOptions>
  </ButtonItem>

</Form>

</form>
            </Item>
        <Item title={t("Exercise")} icon="floppy">
              <Lesson3Exercise />
            </Item>
        </TabPanel>
        <Popup
          visible={popupvisible}
          dragEnabled={true}
          hideOnOutsideClick={true}
          // content={popupContent}
          width={500}
          height={380}
          title='Need Help?'
        >
      <div>
        <h6>{t("You have finished half of the course now, do you need any help? Click on the help button and one of our staff will contact you at a later date.")} </h6>
      </div>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={emailButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />

        </Popup>
        <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
        </React.Fragment>
    )
}