import React, { useState, useRef, useCallback } from 'react';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';

export default function ChangePasswordForm() {
  const [loading, setLoading] = useState(false);
  const formData = useRef({ currentpassword: '', newpassword: '' });
  const { user } = useAuth();


  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(backendlink + '/user/changepassword', {
        currentPassword: formData.current.currentpassword,
        newPassword: formData.current.newpassword,
        userToken: user.user
      }, {
        headers: {
          token: user.token
        }
      }
      ).then(response => {
        if (response.data.errCode == 0) {
          notify("Password changed successfully", 'success', 2500);
        }
      })

    } catch (error) {
      notify("Password changed unsuccessfully", 'error', 2500);
    }

    setLoading(false);

  }, []);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.newpassword,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'currentpassword'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'newpassword'}
          editorType={'dxTextBox'}
          editorOptions={newpasswordEditorOptions}
        >
          <RequiredRule message="New Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Continue'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Current Password', mode: 'password' };
const newpasswordEditorOptions = { stylingMode: 'filled', placeholder: 'New Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm New Password', mode: 'password' };
