export const tasks = [
  {
  Task_ID: 1,
  Task_Subject: 'Connecting with nature',
  Task_Status: 'Important',
}, {
  Task_ID: 2,
  Task_Subject: 'Gaining wisdom and a mature understanding of life',
  Task_Status: 'Important',
}, {
  Task_ID: 3,
  Task_Subject: 'Being honest',
  Task_Status: 'Not Important',
}, {
  Task_ID: 4,
  Task_Subject: 'Being loyal to friends, family and /or my group',
  Task_Status: 'Average',
}, {
  Task_ID: 5,
  Task_Subject: 'Creating beauty (in any domain including arts, dancing, gardening)',
  Task_Status: 'Not Important',
}, {
  Task_ID: 6,
  Task_Subject: 'Helping others',
  Task_Status: 'Average',
}, 
{
  Task_ID: 7,
  Task_Subject: 'Having a sense of accomplishment and making a lasting contribution',
  Task_Status: 'Important',
}, {
  Task_ID: 8,
  Task_Subject: 'Having relationships involving love and affection',
  Task_Status: 'Important',
}, {
  Task_ID: 9,
  Task_Subject: 'Being ambitious and hard working',
  Task_Status: 'Not Important',
}, {
  Task_ID: 10,
  Task_Subject: 'Having genuine and close friends',
  Task_Status: 'Average',
}, {
  Task_ID: 11,
  Task_Subject: 'Having an exciting life',
  Task_Status: 'Not Important',
}, {
  Task_ID: 12,
  Task_Subject: 'Having life filled with adventure',
  Task_Status: 'Average',
}, 
{
  Task_ID: 13,
  Task_Subject: 'Being physically fit',
  Task_Status: 'Important',
}, {
  Task_ID: 14,
  Task_Subject: 'Eating healthy food',
  Task_Status: 'Important',
}, {
  Task_ID: 15,
  Task_Subject: 'Engaging in sporting activities',
  Task_Status: 'Not Important',
}, {
  Task_ID: 16,
  Task_Subject: 'Being at one with god and the universe',
  Task_Status: 'Average',
}, {
  Task_ID: 17,
  Task_Subject: 'Showing respect for tradition',
  Task_Status: 'Not Important',
}, {
  Task_ID: 18,
  Task_Subject: 'Being self-disciplined and resisting temptation',
  Task_Status: 'Average',
}, 
{
  Task_ID: 19,
  Task_Subject: 'Meeting my obligations',
  Task_Status: 'Important',
}, {
  Task_ID: 20,
  Task_Subject: 'Maintaining the safety and security of my loved ones',
  Task_Status: 'Important',
}, {
  Task_ID: 21,
  Task_Subject: 'Making sure to repay favours and not be indebted to people',
  Task_Status: 'Not Important',
}, {
  Task_ID: 22,
  Task_Subject: 'Being wealthy',
  Task_Status: 'Average',
}, {
  Task_ID: 23,
  Task_Subject: 'Having authority, being in charge',
  Task_Status: 'Not Important',
}, {
  Task_ID: 24,
  Task_Subject: 'Having influence over people',
  Task_Status: 'Average',
}, 
{
  Task_ID: 25,
  Task_Subject: 'Enjoying food and drink',
  Task_Status: 'Important',
}, {
  Task_ID: 26,
  Task_Subject: 'Being sexually active',
  Task_Status: 'Important',
}, {
  Task_ID: 27,
  Task_Subject: 'Being creative',
  Task_Status: 'Not Important',
}, {
  Task_ID: 28,
  Task_Subject: 'Being curious, discovering new things',
  Task_Status: 'Average',
}, {
  Task_ID: 29,
  Task_Subject: 'Figuring things out, solving problems',
  Task_Status: 'Not Important',
}, {
  Task_ID: 30,
  Task_Subject: 'Striving to be a better person',
  Task_Status: 'Average',
}, 
{
  Task_ID: 31,
  Task_Subject: 'Feeling good about myself',
  Task_Status: 'Important',
}, {
  Task_ID: 32,
  Task_Subject: 'Leading a stress-free life',
  Task_Status: 'Important',
}, {
  Task_ID: 33,
  Task_Subject: 'Enjoying music, art, and/or drama',
  Task_Status: 'Not Important',
}, {
  Task_ID: 34,
  Task_Subject: 'Teaching others',
  Task_Status: 'Average',
}, {
  Task_ID: 35,
  Task_Subject: 'Resolving disputes',
  Task_Status: 'Not Important',
}, {
  Task_ID: 36,
  Task_Subject: 'Building and repairing things',
  Task_Status: 'Average',
}, 
{
  Task_ID: 37,
  Task_Subject: 'Organising things',
  Task_Status: 'Important',
}, {
  Task_ID: 38,
  Task_Subject: 'Engaging in clearly defined work',
  Task_Status: 'Important',
}, {
  Task_ID: 39,
  Task_Subject: 'Exploring / researching things',
  Task_Status: 'Not Important',
}, {
  Task_ID: 40,
  Task_Subject: 'Promoting justice and caring for the weak',
  Task_Status: 'Average',
}, {
  Task_ID: 41,
  Task_Subject: 'Being sexually desirable',
  Task_Status: 'Not Important',
}, {
  Task_ID: 42,
  Task_Subject: 'Being competent and effective',
  Task_Status: 'Average',
}, 
{
  Task_ID: 43,
  Task_Subject: 'Having a life filled with novelty and change',
  Task_Status: 'Important',
}, {
  Task_ID: 44,
  Task_Subject: 'Acting consistently with my religious beliefs',
  Task_Status: 'Important',
}, {
  Task_ID: 45,
  Task_Subject: 'Showing respect to parents and elders',
  Task_Status: 'Not Important',
}, {
  Task_ID: 46,
  Task_Subject: 'Being safe from danger',
  Task_Status: 'Average',
}, {
  Task_ID: 47,
  Task_Subject: 'Having an enjoyable, leisurely life',
  Task_Status: 'Not Important',
}, {
  Task_ID: 48,
  Task_Subject: 'Being self-sufficient',
  Task_Status: 'Average',
}, 
{
  Task_ID: 49,
  Task_Subject: 'Experiencing positive mood states',
  Task_Status: 'Important',
}, {
  Task_ID: 50,
  Task_Subject: 'Working outdoors',
  Task_Status: 'Important',
}, {
  Task_ID: 51,
  Task_Subject: 'Managing things',
  Task_Status: 'Not Important',
}, {
  Task_ID: 52,
  Task_Subject: 'Designing things',
  Task_Status: 'Average',
}

];
