import React, { useState, useEffect } from 'react';
import exercise5diagram from './exercise5diagram.jpg';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
// import './Lesson4Exercise.scss';

export default function Lesson5Exercise(){

  const { user } = useAuth();

  const { t } = useTranslation();


  const [tableData, setTableData] = useState([
    { step: "1", question: 'Think about the last time you felt bothered by FCR. What was the first triggering thought for your FCR worry? (You can refer to the material from session 2).', column1: ''},
    { step: "2", question: 'When those thoughts or feelings arise, what other worries are triggered? What’s the worst that could happen?',column1: ''},
    { step: "3", question: 'How much do you believe that worries are uncontrollable or harmful? How might worry harm you?',column1: ''},
    { step: "4", question: 'Are there any advantages to having FCR worries? Do FCR worries help you in any way?',column1: ''},
    { step: "5a", question: 'Problematic Styles of Information Processing - Cognitive strategies and behaviours:',column1: ''},
    { step: "5b", question: 'Problematic Styles of Information Processing - Emotions：',column1: ''},
    { step: "5c", question: 'Problematic Styles of Information Processing - Attention：',column1: ''},
  ]);


  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.post(backendlink + '/exercise/getexercise',
          {
            lessonNumber: 5,
            exerciseNumber: 1
          },  
          {
              headers: {
                token: user.token
              }        
          });
          if (response.data.errMessage !== "No exercise found"){
            const data = JSON.parse(response.data.content);
            const updatedTableData = tableData.map((item, index) => ({
              ...item,
              column1: data.tableData[index],
            }));
            setTableData(updatedTableData);
            // setTable1Data(data.table1Data)
          }

        } catch (error) {
          console.log(error);
          alert(error);
        }
      };
      
      fetchData();
  }, []);

  const handleInputChange = (event, rowIndex, columnName) => {
    const newData = [...tableData];
    newData[rowIndex][columnName] = event.target.value;
    setTableData(newData);
  };

  const handleSubmission = async () => {
    const tableDataColumns = tableData.map(item => item.column1);

    const data = {
      tableData: tableDataColumns
    };

  
    const jsonData = JSON.stringify(data);
  
    try {
      const response = await axios.post(backendlink + '/exercise/submitexercise',
      {
        lessonNumber: 5,
        exerciseNumber: 1, 
        content: jsonData
      },  
      {
          headers: {
            token: user.token
          }        
      });
      
    } catch (error) {
      console.log(error);
      alert(error);
    }  };

  return (
    <div>
      <img src={exercise5diagram} style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}/>
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop:"30px", marginBottom: "20px"}}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.question}>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{t(row.step)}</td>
              <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left', width:'40%' }}>{t(row.question)}</td>
              <td>
                <input
                  type="text"
                  value={row.column1}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column1')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button onClick={handleConsoleLog}>Console Log Table Data</button> */}
      <Button text={t("Submit")} type="default" onClick={handleSubmission}/>

    </div>
  );
};