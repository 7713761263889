import React, { useState, useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';

// import './Table.css';

export default function Lesson3Exercise(){

  const { user } = useAuth();

  const { t } = useTranslation();


  const [tableData, setTableData] = useState([
    { week: 1, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 1, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 2, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 2, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 3, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 3, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 4, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 4, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 5, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 5, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 6, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 6, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 7, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 7, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 8, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 8, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 9, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 9, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 10, focus: 'Before', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
    { week: 10, focus: 'After', mon: "", tues: "", wed: "", thur: "", fri: "", sat: "", sun: "", comments: ""},
  ]);

  const tableHeaders = [
    'Week',
    'How self-focused are you?',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
    'Comments / Questions',
  ];

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.post(backendlink + '/exercise/getexercise',
          {
            lessonNumber: 3,
            exerciseNumber: 1
          },  
          {
              headers: {
                token: user.token
              }        
          });

          if (response.data.errMessage !== "No exercise found"){
            const data = JSON.parse(response.data.content);
            const updatedTableData = tableData.map((item, index) => ({
              ...item,
              ...data.tableData[index],
            }));
            setTableData(updatedTableData);
          }
        } catch (error) {
          console.log(error);
          alert(error);
        }
      };
      
      fetchData();
  }, []);

  const handleInputChange = (event, rowIndex, columnName) => {
    const newData = [...tableData];
    newData[rowIndex][columnName] = event.target.value;
    setTableData(newData);
  };

  const handleSubmission = async () => {

    const newDataArray = tableData.map(({ week, focus, ...rest }) => rest);

    const data = {
      tableData: newDataArray
    };

    const jsonData = JSON.stringify(data);
  
    try {
      const response = await axios.post(backendlink + '/exercise/submitexercise',
      {
        lessonNumber: 3,
        exerciseNumber: 1, 
        content: jsonData
      },  
      {
          headers: {
            token: user.token
          }        
      });

    } catch (error) {
      console.log(error);
      alert(error);
    }  };

  return (
    <div>
      <table style={{marginTop: "20px", marginBottom: "20px" }}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>{t(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.week + row.focus}>
              <td>{t(row.week)}</td>
              <td>{t(row.focus)}</td>
              <td>
                <input
                  type="text"
                  value={row.mon}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'mon')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.tues}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'tues')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.wed}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'wed')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.thur}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'thur')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.fri}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'fri')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.sat}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'sat')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.sun}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'sun')
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.comments}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'comments')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button onClick={handleConsoleLog}>Console Log Table Data</button> */}
      <Button text={t("Submit")} type="default" onClick={handleSubmission}/>

    </div>
  );
};