import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CheckBox } from 'devextreme-react';

import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { backendlink } from '../../config';

export default function ConsentForm() {

    const { user, updateUserConsent } = useAuth();

    const [consentChecked, setConsentChecked] = useState(false);

    const handleConsentChange = (e) => {
        setConsentChecked(e.value);
    };

    const { t } = useTranslation();


    async function SubmitConsent(event) {
        // event.preventDefault();
        try {
            const result = await axios.post(backendlink + '/user/submitconsent', {
                userToken: user.user
            }, {
                headers: {
                    token: user.token
                }
            })
                .then((res) => {
                    if (res.data.errCode == 0) {
                        updateUserConsent(res.data.consent);
                    }
                    else {
                        alert("System error")
                    }
                });
        } catch (error) {
            console.log("error", error)
        }
    };

    return (
        <div>
            <p style={{fontSize: "28px", textAlign: "center"}}><strong>{t("Conquering fear online: a pilot randomized controlled trial assessing the efficacy and feasibility of an internet-based self-help intervention on subclinical fear of cancer recurrence")}</strong></p>
            
            <p style={{fontSize: "30px", textAlign: "center", textDecoration: "underline"}}><strong>{t("Consent Form")}</strong></p>

            <p style={{fontSize: "20px"}}>{t("I have read the attached information sheet explaining the purpose and the nature of this study. I have understood the information about this study that was given to me. I understand the purpose of this study is to assess the effectiveness of eConquerFear and eCare interventions on fear of cancer recurrence among Chinese patients newly diagnosed with curable breast or colorectal cancer. The findings enable us to develop an effective, less resource intensive intervention specifically for patients with subclinical fear of cancer reucrrrence, so to better meet their needs and improve their quality of life.")}</p>

            <p style={{fontSize: "20px"}}>{t("I understand that I will be randomly allocated to the eConquerFear or the eCare intervention.")}</p>

            <p style={{fontSize: "20px"}}>{t("I understand the study may require me to complete 6 self-directed online modules over 6 weeks.")}</p>

            <p style={{fontSize: "20px"}}>{t("I understand my participation will involve completing a screening questionnaire, and a 30 minutes face-to-face interview if I score 13-21 on the screening questionnaire, and will be asked to do three additional assessments later.")}</p>

            <p style={{fontSize: "20px"}}>{t("I understand that the information I provide is confidential and will not be revealed to anyone. I have been given the opportunity to ask questions about this study and they have been answered to my satisfaction.")}</p>

            <p style={{fontSize: "20px"}}>{t("I consent to participate in this study and to give permission to access medical information in my medical record. I understand that I have right to withdraw at any time with no negative consequences, and my care will remain the same.")}</p>

            {/* <p>{t("Dear Sir/Madam:")}</p>
            <p>
                {t("Fear of cancer recurrence (FCR) is a normal and understandable response to a cancer diagnosis. However, untreated and excessive fear of cancer recurrence is associated with poor quality of life and impaired functioning. Among local Chinese cancer survivors, 26% and 11% experienced subclinical and clinically-significant levels of FCR, respectively. Despite a number of psychological interventions for FCR available, limited healthcare resources limit its reach to all patients in needed. They are usually reserved for those with clinically-significant FCR, highlighting the need to develop an effective, but less resource intensive intervention specifically for patients with subclinical FCR.")}
            </p>
            <p>
                {t("Our research team has recently developed two internet-based self-help interventions for FCR, namely eLearn and eCare, respectively. So far, the effectiveness of both interventions is unknown. Therefore, the School of Public Health is in collaboration with the Department of Surgery at the University of Hong Kong on a pilot randomized controlled trial which aims to develop and evaluate the effectiveness of eLearn and eCare on FCR among Chinese patients diagnosed with curable breast or colorectal cancer. The findings would allow us to develop a cost-effective intervention for FCR and so to improve affected patients’ quality of life.")}
            </p>
            <p>
                {t("To make this a fair comparison, participants who agree to take part will be randomly allocated to receive either the eLearn or the eCare intervention. The intervention allocation is based on a computer-generated randomization sequence. The randomized results will be put into serially labeled opaque sealed envelopes. You will have a fifty-fifty chance being assigned to receive the eLearn or the eCare intervention.")}
            </p>
            <p><strong style={{ textDecoration: 'underline' }}>{t("What will happen if I agree to participate?")}</strong></p>

            <p>
                {t("If you agree to participate in the study, you will be invited to complete a screening questionnaire after you sign the consent form. Those who score below 13 and greater than 21 will be given our helpline number or referred to see our counsellors, respectively, if they wish to seek support. Those who score 13-21 will complete the baseline questionnaire immediately. The participation will last about 30 minutes. The research staff will break a serially labeled opaque sealed envelope after the completion of baseline questionnaire. You will be informed of your intervention allocation. You will be asked to complete the same questionnaire again 3 times more after the intervention, 3-months post-intervention and 6-months post-intervention.")}
            </p>
            <p><strong style={{ textDecoration: 'underline' }}>{t("What will happen if I am assigned to the eLearn intervention?")} </strong></p>

            <p>
                {t("If you are randomly assigned to receive the eLearn intervention, you will receive 6 self-directed online modules over 6 weeks. The access links to each module will be sent to you through instant messaging weekly. The key goals of this intervention are the following: teach strategies for controlling worry and excessive threat monitoring, modify underlying unhelpful beliefs about worry, develop appropriate monitoring and screening behaviours, encourage acceptance of the uncertainty brought about by a cancer diagnosis, and clarify values and encourage engagement in values-based goal setting.")}
            </p>
            <p><strong style={{ textDecoration: 'underline' }}>{t("What will happen if I am assigned to the Basic Cancer Care  group?")}</strong></p>

            <p>
                {t("If you are randomly assigned to receive the eCare intervention, you will receive 6 self-directed online modules over 6 weeks. The access links to each module will be sent to you through instant messaging weekly. The key goals of this intervention are to teach relaxation techniques and offer dietary and exercise advices, which are desiged to help cancer patients to enhance perceived control over illness, thereby leading to better adjustment to cancer.")}
            </p>
            <p><strong style={{ textDecoration: 'underline' }}>{t("What are my rights if I participate? How will my information be protected?")}</strong></p>

            <p>
                {t("You have the rights of access to personal data and publicly available study results, if and when needed. Under the laws of Hong Kong (in particular the Personal Data (Privacy) Ordinance, Cap 486), you enjoy or may enjoy rights for the protection of the confidentiality of your personal data, such as those regarding the collection, custody, retention, management, control, use (including analysis or comparison), transfer in or out of Hong Kong, non-disclosure, erasure and/or in any way dealing with or disposing of any of your personal data in or for this study. For any query, you should consult the Privacy Commissioner for Privacy Data or their office (Tel No. 2827 2827) as to the proper monitoring or supervision of your personal data protection so that your full awareness and understanding of the significance of compliance with the law governing privacy data is assured.")}
            </p>
            <p style={{ textDecoration: 'underline' }}> {t("Confidentiality")}</p>
            <p>
                {t("By consenting to participate in this study, you expressly authorize:")}
            </p>
            <ul>
                <li>{t("The principal investigator and his research team and the ethics committee (Institutional Review Board of the University of Hong Kong / Hospital Authority Hong Kong West Cluster ) responsible for overseeing this study to get access to, to use, and to retain your personal data for the purposes and in the manner described in this informed consent process; and")}</li>
                <li>{t("The relevant government agencies (e.g. the Hong Kong Department of Health) to get access to your personal data for the purposes of checking and verifying the integrity of study data and assessing compliance with the study protocol and relevant requirements.")}</li>
                <li>{t("The information on the questionnaire will be coded: it will not be linked to your name, so it will be fully anonymous and confidential. Your identity will not be revealed while the study is being conducted or when the study is reported or published. All study data will be stored in a secure place, and not shared with any other person.")}</li>
            </ul>

            <p>
                {t("Dr. Danielle Ng will be overseeing this project. If you have any questions about this project, please do not hesitate to contact the principal investigator of the study (Tel  3917 9897, Dr. Danielle Ng).")}
            </p> */}
            {/* <CheckBox text='I hereby consent to participate in the study entitled “Conquering fear online: a pilot randomized controlled trial assessing the efficacy and feasibility of an internet-based self-help intervention on subclinical fear of cancer recurrence'></CheckBox>
    <Button text="Submit" type="default" onClick={SubmitConsent} style={{ marginBottom: '10px' }}/> */}
            <CheckBox
                checked={consentChecked}
                onValueChanged={handleConsentChange}
                style={{fontSize: "20px"}}
                text={t("I hereby consent to participate in the study entitled “Conquering fear online: a pilot randomized controlled trial assessing the efficacy and feasibility of an internet-based self-help intervention on subclinical fear of cancer recurrence”")}
            ></CheckBox>
            <br />
            <Button
                text={t("Submit")}
                type="default"
                onClick={SubmitConsent}
                style={{ marginTop: '10px' }}
                disabled={!consentChecked}
            >
                {t("Submit")}
            </Button>
        </div>
    )

}
