// export const section1 = [
//     {
//       "id": "QLQ1",
//       "text": "Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
//       "options": [
//         { "score": "0", "text": "Not at All" },
//         { "score": "1", "text": "A Little" },
//         { "score": "2", "text": "Quite a Bit" },
//         { "score": "3", "text": "Very Much" },
//       ],
//       "type": "dxRadioGroup"
//     },
//     {
//       "id": "QLQ2",
//       "text": "Do you have any trouble taking a long walk?",
//       "options": [
//         { "score": "0", "text": "Not at All" },
//         { "score": "1", "text": "A Little" },
//         { "score": "2", "text": "Quite a Bit" },
//         { "score": "3", "text": "Very Much" },
//       ],
//       "type": "dxRadioGroup"
//     },    
//     {
//       "id": "QLQ3",
//       "text": "Do you have any trouble taking a short walk outside of the house?",
//       "options": [
//         { "score": "0", "text": "Not at All" },
//         { "score": "1", "text": "A Little" },
//         { "score": "2", "text": "Quite a Bit" },
//         { "score": "3", "text": "Very Much" },
//       ],
//       "type": "dxRadioGroup"
//     },
//   ]

//   export const section2 = [
//     {
//       "id": "MCQ1",
//       "text": "I do not trust my memory",
//       "options": [
//         { "score": "1", "text": "Disagree" },
//         { "score": "2", "text": "Slightly agree" },
//         { "score": "3", "text": "Moderately agree" },
//         { "score": "4", "text": "Very much agree" },
//       ],
//       "type": "dxRadioGroup"
//     },
//   ]
//   export const section3 = [
//     {
//       "id": "HAD1",
//       "text": "I feel tense or ‘wound up':",
//       "options": [
//         { "score": "0", "text": "Not at All" },
//         { "score": "1", "text": "From time to time, occasionally" },
//         { "score": "2", "text": "A lot of the time" },
//         { "score": "3", "text": "Most of the time" },
//       ],
//       "type": "dxRadioGroup"
//     },
//   ]
//   export const section4 = [
//     {
//       "id": "FRCI1",
//       "text": "Television shows or newspaper articles about cancer or illness",
//       "options": [
//         { "score": "0", "text": "Never" },
//         { "score": "1", "text": "Rarely" },
//         { "score": "2", "text": "Sometimes" },
//         { "score": "3", "text": "Most of the time" },
//       ],
//       "type": "dxRadioGroup"
//     },
//   ]
//   export const section5 = [
//     {
//       "id": "D1",
//       "text": "Age",
//       "type": "dxTextBox"
//     },
//   ]

export const section1 = [
  {
    "description": "We are interested in some things about you and your health. Please answer all of the questions yourself by circling the number that best applies to you. There are no 'right' or 'wrong' answers. The information that you provide will remain strictly confidential."
  },
  {
    "id": "QLQ1",
    "text": "Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ2",
    "text": "Do you have any trouble taking a long walk?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ3",
    "text": "Do you have any trouble taking a short walk outside of the house?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ4",
    "text": "Do you need to stay in bed or a chair during the day?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ5",
    "text": "Do you need help with eating, dressing, washing yourself or using the toilet?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "During the past week (past 7 days):"
  },
  {
    "id": "QLQ6",
    "text": "Were you limited in doing either your work or other daily activities?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ7",
    "text": "Were you limited in pursuing your hobbies or other leisure time activities?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ8",
    "text": "Were you short of breath?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ9",
    "text": "Have you had pain?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ10",
    "text": "Did you need to rest?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ11",
    "text": "Have you had trouble sleeping?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ12",
    "text": "Have you felt weak?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ13",
    "text": "Have you lacked appetite?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ14",
    "text": "Have you felt nauseated?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ15",
    "text": "Have you vomited?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ16",
    "text": "Have you been constipated?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ17",
    "text": "Have you had diarrhea?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ18",
    "text": "Were you tired?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ19",
    "text": "Did pain interfere with your daily activities?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ20",
    "text": "Have you had difficulty in concentrating on things, like reading a newspaper or watching television?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ21",
    "text": "Did you feel tense?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ22",
    "text": "Did you worry?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ23",
    "text": "Did you feel irritable?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ24",
    "text": "Did you feel depressed?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ25",
    "text": "Have you had difficulty remembering things?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ26",
    "text": "Has your physical condition or medical treatment interfered with your family life?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ27",
    "text": "Has your physical condition or medical treatment interfered with your social activities?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ28",
    "text": "Has your physical condition or medical treatment caused you financial difficulties?",
    "options": [
      { "score": "0", "text": "Not at All" },
      { "score": "1", "text": "A Little" },
      { "score": "2", "text": "Quite a Bit" },
      { "score": "3", "text": "Very Much" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "For the following questions please circle the number between 1 and 7 that best applies to you."
  },
  {
    "id": "QLQ29",
    "text": "How would you rate your overall health during the past week (past 7 days)?",
    "options": [
      { "score": "1", "text": "1 (Very Poor)" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7 (Excellent)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "QLQ30",
    "text": "How would you rate your overall quality of life during the past week (past 7 days)?",
    "options": [
      { "score": "1", "text": "1 (Very Poor)" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7 (Excellent)" }
    ],
    "type": "dxRadioGroup"
  },
  // {
  //     "id": "QLQ29",
  //     "text": "How would you rate your overall health during the past week (past 7 days)?",
  //     "options": [
  //       { "score": "1", "text": "Very Poor" },
  //       { "score": "2", "text": "Poor" },
  //       { "score": "3", "text": "below Fair" },
  //       { "score": "4", "text": "Fair" },
  //       { "score": "5", "text": "Good" },
  //       { "score": "6", "text": "Very Good" },
  //       { "score": "7", "text": "Excellent" }
  //     ],
  //     "type": "dxRadioGroup"
  //   },
  //   {
  //     "id": "QLQ30",
  //     "text": "How would you rate your overall quality of life during the past week (past 7 days)?",
  //     "options": [
  //       { "score": "1", "text": "Very Poor" },
  //       { "score": "2", "text": "Poor" },
  //       { "score": "3", "text": "below Fair" },
  //       { "score": "4", "text": "Fair" },
  //       { "score": "5", "text": "Good" },
  //       { "score": "6", "text": "Very Good" },
  //       { "score": "7", "text": "Excellent" }
  //     ],
  //     "type": "dxRadioGroup"
  //   },  	  
]
export const section2 = [
  {
    "description": "Listed below are a number of beliefs that people have expressed. Please read each item and indicate how much you generally agree with it." 
  },
  {
    "id": "MCQ1",
    "text": "I do not trust my memory",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ2",
    "text": "I have a poor memory",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ3",
    "text": "I have little confidence in my memory for actions",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ4",
    "text": "I have little confidence in my memory for places",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ5",
    "text": "I have little confidence in my memory for words and names",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ6",
    "text": "My memory can mislead me at times",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ7",
    "text": "Worrying helps me to get things sorted out in my mind",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ8",
    "text": "Worrying helps me cope",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ9",
    "text": "I need to worry in order to work well",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ10",
    "text": "Worrying helps me to solve problems",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ11",
    "text": "I need to worry in order to remain organised",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ12",
    "text": "Worrying helps me to avoid problems in the future",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ13",
    "text": "I am constantly aware of my thinking",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ14",
    "text": "I pay close attention to the way my mind works",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ15",
    "text": "I think a lot about my thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ16",
    "text": "I constantly examine my thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ17",
    "text": "I monitor my thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ18",
    "text": "I am aware of the way my mind works when I am thinking through a problem",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ19",
    "text": "My worrying thoughts persist, no matter how I try to stop them",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ20",
    "text": "When I start worrying I cannot stop",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ21",
    "text": "I could make myself sick with worrying",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ22",
    "text": "I cannot ignore my worrying thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ23",
    "text": "My worrying could make me go mad",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ24",
    "text": "My worrying is dangerous for me",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ25",
    "text": "If I could not control my thoughts, I would not be able to function",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ26",
    "text": "Not being able to control my thoughts is a sign of weakness",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ27",
    "text": "I should be in control of my thoughts all of the time",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ28",
    "text": "It is bad to think certain thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ29",
    "text": "If I did not control a worrying thought and then it happened, it would be my fault",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "MCQ30",
    "text": "I will be punished for not controlling certain thoughts",
    "options": [
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Slightly agree" },
      { "score": "3", "text": "Moderately agree" },
      { "score": "4", "text": "Very much agree" },
    ],
    "type": "dxRadioGroup"
  },
]
export const section3 = [
  {
    "description": "We want to know how you feel. Read each item and check the box of the item which comes closest to how you have been feeling in the past week."
  },
  {
    "id": "HAD1",
    "text": "I feel tense or ‘wound up':",
    "options": [
      { "score": "0", "text": "HAD1 Not at all" },
      { "score": "1", "text": "From time to time, occasionally" },
      { "score": "2", "text": "A lot of the time" },
      { "score": "3", "text": "Most of the time" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD2",
    "text": "I still enjoy the thing I used to enjoy:",
    "options": [
      { "score": "0", "text": "Definitely as much" },
      { "score": "1", "text": "Not quite so much" },
      { "score": "2", "text": "Only a little" },
      { "score": "3", "text": "Hardly at all" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD3",
    "text": "I get a sort of frightened feeling as if something awful is about to happen:",
    "options": [
      { "score": "3", "text": "Very definitely and quite badly" },
      { "score": "2", "text": "Yes, but not too badly" },
      { "score": "1", "text": "A little, but it doesn't worry me" },
      { "score": "0", "text": "Not at all" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD4",
    "text": "I can laugh and see the funny side of things:",
    "options": [
      { "score": "0", "text": "As much as I always could" },
      { "score": "1", "text": "Not quite so much now" },
      { "score": "2", "text": "Definitely not so much now" },
      { "score": "3", "text": "HAD4 Not at all" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD5",
    "text": "Worrying thoughts go through my mind:",
    "options": [
      { "score": "3", "text": "A great deal of the time" },
      { "score": "2", "text": "HAD5 A lot of the time" },
      { "score": "1", "text": "From time to time but not too often" },
      { "score": "0", "text": "Only occasionally" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD6",
    "text": "I feel cheerful:",
    "options": [
      { "score": "0", "text": "Most of the time" },
      { "score": "1", "text": "Sometimes" },
      { "score": "2", "text": "Not often" },
      { "score": "3", "text": "HAD6 Not at all" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD7",
    "text": "I can sit at ease and feel relaxed:",
    "options": [
      { "score": "0", "text": "Definitely" },
      { "score": "1", "text": "Usually" },
      { "score": "2", "text": "HAD7 Not often" },
      { "score": "3", "text": "HAD7 Not at all" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD8",
    "text": "I feel as if I am slowed down:",
    "options": [
      { "score": "3", "text": "Nearly all the time" },
      { "score": "2", "text": "Very often" },
      { "score": "1", "text": "HAD8 Sometimes" },
      { "score": "0", "text": "Not at all" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD9",
    "text": "I get a sort of frightened feeling like ‘butterflies' in the stomach:",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "Occasionally" },
      { "score": "2", "text": "Quite often" },
      { "score": "3", "text": "HAD9 Very often" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD10",
    "text": "I have lost interest in my appearance:",
    "options": [
      { "score": "3", "text": "HAD10 Definitely" },
      { "score": "2", "text": "I don't take so much care as I should" },
      { "score": "1", "text": "I may not take quite as much care" },
      { "score": "0", "text": "I take just as much care as ever" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD11",
    "text": "I feel restless as if I have to be on the move:",
    "options": [
      { "score": "3", "text": "Very much indeed" },
      { "score": "2", "text": "Quite a lot" },
      { "score": "1", "text": "Not very much" },
      { "score": "0", "text": "Not at all" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD12",
    "text": "I look forward with enjoyment to things:",
    "options": [
      { "score": "0", "text": "As much as ever I did" },
      { "score": "1", "text": "Rather less than I used to" },
      { "score": "2", "text": "Definitely less than I used to" },
      { "score": "3", "text": "HAD12 Hardly at all" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD13",
    "text": "I get sudden feelings of panic:",
    "options": [
      { "score": "3", "text": "Very often indeed" },
      { "score": "2", "text": "HAD13 Quite often" },
      { "score": "1", "text": "Not very often" },
      { "score": "0", "text": "Not at all" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "HAD14",
    "text": "I can enjoy a good book or radio or TV programme:",
    "options": [
      { "score": "0", "text": "Often" },
      { "score": "1", "text": "HAD14 Sometimes" },
      { "score": "2", "text": "HAD14 Not often" },
      { "score": "3", "text": "Very seldom" }
    ],
    "type": "dxRadioGroup"
  },
]

export const section4 = [
  {
    "description": "Most people who have been diagnosed with cancer are worried, to varying degrees, that there might be a recurrence of the cancer. By recurrence, we mean the possibility that the cancer could return or progress in the same place or in another part of the body. This questionnaire aims to better understand the experience of worries about cancer recurrence. Please read each statement and indicate to what degree it applied to you DURING THE PAST MONTH by circling the appropriate number."
  },
  {
    "description": "The following situations make me think about the possibility of cancer recurrence:"
  },

  {
    "id": "FRCI1",
    "text": "Television shows or newspaper articles about cancer or illness",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI2",
    "text": "An appointment with my doctor or other health professional",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI3",
    "text": "Medical examinations (e.g. annual check-up, blood tests, X-rays)",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI4",
    "text": "Conversations about cancer or illness in general",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI5",
    "text": "Seeing or hearing about someone who is ill",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI6",
    "text": "Going to a funeral or reading the obituary section of the paper",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI7",
    "text": "When I feel unwell physically or when I am sick",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI8",
    "text": "Generally, I avoid situations or things that make me think about the possibility of cancer recurrence",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRC1-FRCI8 Sometimes" },
      { "score": "3", "text": "FRC1-FRCI8 Most of the time" },
      { "score": "4", "text": "All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI9",
    "text": "I am worried or anxious about the possibility of cancer recurrence",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI10",
    "text": "I am afraid of cancer recurrence",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI11",
    "text": "I believe it is normal to be worried or anxious about the possibility of cancer recurrence",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI12",
    "text": "When I think about the possibility of cancer recurrence, this triggers other unpleasant thoughts or images (such as death, suffering, the consequences for my family)",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI13",
    "text": "I believe that I am cured and that the cancer will not come back",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI14",
    "text": "In your opinion, are you at risk of having a cancer recurrence?",
    "options": [
      { "score": "0", "text": "Not at all at risk" },
      { "score": "1", "text": "A little at risk" },
      { "score": "2", "text": "Some-what at risk" },
      { "score": "3", "text": "A lot at risk" },
      { "score": "4", "text": "A great deal at risk" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI15",
    "text": "How often do you think about the possibility of cancer recurrence?",
    "options": [
      { "score": "0", "text": "Never" },
      { "score": "1", "text": "A few times a month" },
      { "score": "2", "text": "A few times a week" },
      { "score": "3", "text": "A few times a day" },
      { "score": "4", "text": "Several times a day" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI16",
    "text": "How much time per day do you spend thinking about the possibility of cancer recurrence?",
    "options": [
      { "score": "0", "text": "I don't think about it" },
      { "score": "1", "text": "A few seconds" },
      { "score": "2", "text": "A few minutes" },
      { "score": "3", "text": "A few hours" },
      { "score": "4", "text": "Several hours" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI17",
    "text": "How long have you been thinking about the possibility of cancer recurrence?",
    "options": [
      { "score": "0", "text": "I don't think about it" },
      { "score": "1", "text": "A few weeks" },
      { "score": "2", "text": "A few months" },
      { "score": "3", "text": "A few years" },
      { "score": "4", "text": "Several years" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "When I think about the possibility of cancer recurrence, I feel:"
  },
  {
    "id": "FCRI18",
    "text": "Worry, fear or anxiety",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI19",
    "text": "Sadness, discouragement or disappointment",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI20",
    "text": "Frustration, anger or outrage",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI21",
    "text": "Helplessness or resignation",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "My thoughts or fears about the possibility of cancer recurrence disrupt:"
  },
  {

    "id": "FCRI22",
    "text": "My social or leisure activities (e.g. outings, sports, travel)",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI23",
    "text": "My work or everyday activities",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI24",
    "text": "My relationship with my partner, my family, or those close to me",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI25",
    "text": "My ability to make future plans or set life goals",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI26",
    "text": "My state of mind or my mood",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI27",
    "text": "My quality of life in general",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI28",
    "text": "I feel that I worry excessively about the possibility of cancer recurrence",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI29",
    "text": "Other people think that I worry excessively about the possibility of cancer recurrence",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI30",
    "text": "I think that I worry more about the possibility of cancer recurrence than other people who have diagnosed with cancer",
    "options": [
      { "score": "0", "text": "Not at all" },
      { "score": "1", "text": "A little" },
      { "score": "2", "text": "Some-what" },
      { "score": "3", "text": "A lot" },
      { "score": "4", "text": "A great deal" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "When I think about the possibility of cancer recurrence, I use the following strategies to reassure myself:"
  },
  {
    "id": "FCRI31",
    "text": "I call my doctor or another health professional",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI32",
    "text": "I go to the hospital or clinic for an examination",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI33",
    "text": "I examine myself to see if I have any physical signs of cancer",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI34",
    "text": "I try to distract myself (e.g. do various activities, watch TV, read, work)",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI35",
    "text": "I try not to think about it, to get the idea out of my mind",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI36",
    "text": "I pray, meditate or do relaxation",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI37",
    "text": "I try to convince myself that everything will be fine or I think positively",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI38",
    "text": "I talk to someone about it",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI39",
    "text": "I try to understand what is happening and deal with it",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI40",
    "text": "I try to find a solution",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI41",
    "text": "I try to replace this thought with a more pleasant one",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI42",
    "text": "I tell myself “stop it”",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "FCRI43",
    "text": "Do you feel reassured when you use these strategies?",
    "options": [
      { "score": "0", "text": "FRCI31-FRCI43 Never" },
      { "score": "1", "text": "Rarely" },
      { "score": "2", "text": "FRCI31-FRCI43 Sometimes" },
      { "score": "3", "text": "FRCI31-FRCI43 Most of the time" },
      { "score": "4", "text": "FRCI31-FRCI43 All the time" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "ESAS_FCR",
    "text": "Please circle the number that best describes how you feel now: (FCR = fear that your cancer will come back or get worse)",
    "options": [
      { "score": "0", "text": "0 (No FCR)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8" },
      { "score": "9", "text": "9" },
      { "score": "10", "text": "10 (Worst possible FCR)" }
    ],
    "type": "dxRadioGroup"
  }
]

export const section5 = [
  {
    "id": "CAS1",
    "text": "How much time in the last week have you found yourself dwelling on or worrying about your problems?",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS2",
    "text": "How much time in the last week have you been focusing attention on the things you find threatening (e.g. symptoms, thoughts, danger)?",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "How often in the last week have you done the following in order to cope with your negative feelings or thoughts?"
  },
  {
    "id": "CAS3",
    "text": "Avoided situations",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS4",
    "text": "Tried not to think about things",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS5",
    "text": "Used alcohol/drugs",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS6",
    "text": "Asked for reassurance",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS7",
    "text": "Tried to control my emotions",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS8",
    "text": "Try to control my symptoms",
    "options": [
      { "score": "0", "text": "0 (None of the time)" },
      { "score": "1", "text": "1" },
      { "score": "2", "text": "2" },
      { "score": "3", "text": "3" },
      { "score": "4", "text": "4" },
      { "score": "5", "text": "5" },
      { "score": "6", "text": "6" },
      { "score": "7", "text": "7" },
      { "score": "8", "text": "8 (All of the time)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "description": "Below are a number of beliefs people have. Indicate how much you believe each one by placing number from the scale below next to each item."
  },
  {
    "id": "CAS9",
    "text": "Worrying too much could harm me",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS10",
    "text": "Strong emotions are dangerous",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS11",
    "text": "I cannot control my thoughts",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS12",
    "text": "Some thoughts could make me lose my mind",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS13",
    "text": "CAS13 Worrying helps me cope",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS14",
    "text": "Focusing on possible threats can keep me safe",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS15",
    "text": "It is important to control my thoughts",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "CAS16",
    "text": "Analysing my problem will help me find answers",
    "options": [
      { "score": "0", "text": "0 (I don't believe this at all)" },
      { "score": "10", "text": "10" },
      { "score": "20", "text": "20" },
      { "score": "30", "text": "30" },
      { "score": "40", "text": "40" },
      { "score": "50", "text": "50" },
      { "score": "60", "text": "60" },
      { "score": "70", "text": "70" },
      { "score": "80", "text": "80" },
      { "score": "90", "text": "90" },
      { "score": "100", "text": "100 (I'm completely convinced this is true)" }
    ],
    "type": "dxRadioGroup"
  },
]

export const section6 = [
  {
    "id": "IT1",
    "text": "I would find internet easy to use.",
    "options": [
      { "score": "0", "text": "Strongly Disagree" },
      { "score": "1", "text": "Disagree" },
      { "score": "2", "text": "Neutral" },
      { "score": "3", "text": "Agree" },
      { "score": "4", "text": "Strongly agree" }
    ],
    "type": "dxRadioGroup"
  }
]

export const section7 = [
  {
    "id": "D1",
    "text": "Age",
    "type": "dxTextBox"
  },
  {
    "id": "D2",
    "text": "Gender",
    "options": [
      { "score": "1", "text": "Male" },
      { "score": "2", "text": "Female" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D3",
    "text": "Marital Status",
    "options": [
      { "score": "1", "text": "Single" },
      { "score": "2", "text": "Married/Cohabited" },
      { "score": "3", "text": "Divorced/Separated" },
      { "score": "4", "text": "Widowed" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D4",
    "text": "Number of children",
    "type": "dxTextBox"
  },
  {
    "id": "D5",
    "text": "Age of oldest child",
    "type": "dxTextBox"
  },
  {
    "id": "D6",
    "text": "Age of youngest child",
    "type": "dxTextBox"
  },
  {
    "id": "D7",
    "text": "Current size of household (number of people)",
    "type": "dxTextBox"
  },
  {
    "id": "D8",
    "text": "Who are they?",
    // "options": [
    //     { "score": "1", "text": "Father/Mother" },
    //     { "score": "2", "text": "Brothers/Sisters" },
    //     { "score": "3", "text": "Husband/Wife" },
    //     { "score": "4", "text": "Son/Daughter" },
    // { "score": "5", "text": "Blood relatives" },
    // { "score": "6", "text": "Non-blood relatives" },
    // { "score": "7", "text": "Friends" },
    // { "score": "8", "text": "Others" }	 
    //   ],
    "type": "dxTextBox"
  },
  {
    "id": "D9",
    "text": "Educational level",
    "options": [
      { "score": "1", "text": "No formal education" },
      { "score": "2", "text": "Primary education" },
      { "score": "3", "text": "Secondary education" },
      { "score": "4", "text": "Tertiary education" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D10",
    "text": "Occupation",
    "options": [
      { "score": "1", "text": "Full-time" },
      { "score": "2", "text": "Part-time" },
      { "score": "3", "text": "Retired" },
      { "score": "4", "text": "Housewife" },
      { "score": "5", "text": "Unemployed (before the diagnosis of cancer)" },
      { "score": "6", "text": "Unemployed/Resigned (since the diagnosis of cancer)" },
      { "score": "7", "text": "Others" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D10a",
    "text": "Occupation (Please include past job title and previous monthly salary if you are unemployed/resigned)",
    "type": "dxTextBox"
  },
  {
    "id": "D11",
    "text": "Years of Hong Kong residency",
    "options": [
      { "score": "1", "text": "Less than 1 year" },
      { "score": "2", "text": "1 to 3 years" },
      { "score": "3", "text": "4 to 7 years" },
      { "score": "4", "text": "More than 7 years" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D12",
    "text": "Total family income (monthly)",
    "options": [
      { "score": "1", "text": "Less than $10,000" },
      { "score": "2", "text": "$10,001 - $20,000" },
      { "score": "3", "text": "$20,001 - $30,000" },
      { "score": "4", "text": "$30,001 - $40,000" },
      { "score": "5", "text": "Above $40,000" }
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D12a",
    "text": "For those who have resigned or have been unemployed since the diagnosis of cancer, where do you receive financial support?",
    "type": "dxTextBox"
  },
  {
    "id": "D13",
    "text": "Religion",
    "options": [
      { "score": "1", "text": "Christianity" },
      { "score": "2", "text": "Catholic" },
      { "score": "3", "text": "Buddhism" },
      { "score": "4", "text": "Taosim" },
      { "score": "5", "text": "Chinese God worship (such as Wong Tai Sin, Queen Tin Hau, Chei Kung, God of Lands, King Kwan, Guanyin)" },
      { "score": "6", "text": "Ancestor worship" },
      { "score": "7", "text": "Others" },
      { "score": "8", "text": "No religious affiliation" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D13a",
    "text": "Other Religion",
    "type": "dxTextBox"
  },
  {
    "id": "D14",
    "text": "When were you diagnosed as having cancer? (day/ month/ year)",
    "type": "dxTextBox"
  },
  {
    "description": "What surgical treatment did you have? (Patients with breast cancer, please go to D15a; patients with colorectal cancer, please go to D15b",
  },
  {
    "id": "D15a",
    "text": "Breast cancer surgery",
    "options": [
      { "score": "1", "text": "Lumpectomy" },
      { "score": "2", "text": "Mastectomy (one side／both sides)" },
      { "score": "3", "text": "Mastectomy plus breast reconstruction" },
      { "score": "888", "text": "Not applicable" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D15b",
    "text": "Colorectal cancer surgery",
    "options": [
      { "score": "1", "text": "Open surgery" },
      { "score": "2", "text": "Laparoscopic surgery" },
      { "score": "3", "text": "Other surgery" },
      { "score": "888", "text": "Not applicable" },

    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D15b1",
    "text": "If you have received colorectal cancer surgery, do you have colostomy (colostomy bag)?",
    "options": [
      { "score": "0", "text": "No" },
      { "score": "1", "text": "Yes" },
      { "score": "888", "text": "Not applicable" },

    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D16a",
    "text": "Are you currently receiving any treatment?",
    "options": [
      { "score": "0", "text": "No" },
      { "score": "1", "text": "Yes" },

    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D16b",
    "text": "If yes, please specify",
    "options": [
      { "score": "1", "text": "Radiation therapy" },
      { "score": "2", "text": "Chemotherapy" },
      { "score": "3", "text": "Hormonal therapy" },
      { "score": "4", "text": "Target therapy" },
      { "score": "5", "text": "Others" },
      { "score": "888", "text": "Not applicable" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D16b5",
    "text": "Other Cancer Treatment",
    "type": "dxTextBox"
  },
  {
    "id": "D17a",
    "text": "Are you waiting for further cancer treatment?",
    "options": [
      { "score": "1", "text": "Yes" },
      { "score": "0", "text": "No" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D17b",
    "text": "If yes, please specify",
    "options": [
      { "score": "1", "text": "Radiation therapy" },
      { "score": "2", "text": "Chemotherapy" },
      { "score": "3", "text": "Hormonal therapy" },
      { "score": "4", "text": "Target therapy" },
      { "score": "5", "text": "Others" },
      { "score": "888", "text": "Not applicable" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D17b5",
    "text": "Other Cancer Treatment",
    "type": "dxTextBox"
  },
  {
    "id": "D18a",
    "text": "Have you taken Chinese Medicine since you have cancer?",
    "options": [
      { "score": "0", "text": "D18A No" },
      { "score": "1", "text": "D18A Yes" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D18b",
    "text": "If yes, will you continue to take Chinese Medicine?",
    "options": [
      { "score": "0", "text": "No" },
      { "score": "1", "text": "Yes" },
      { "score": "888", "text": "Not applicable" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D18b1",
    "text": "Reason",
    "type": "dxTextBox"
  },
  {
    "id": "D19a",
    "text": "Have you tried other treatments since you have cancer? （i.e. acupuncture, Gi Gong, etc.）",
    "options": [
      { "score": "0", "text": "No" },
      { "score": "1", "text": "Yes" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D19a1",
    "text": "If yes, Please specify",
    "type": "dxTextBox"
  },
  {
    "id": "D19b",
    "text": "If yes, will you continue to have this treatment?",
    "options": [
      { "score": "0", "text": "No" },
      { "score": "1", "text": "Yes" },
      { "score": "888", "text": "Not applicable" },
    ],
    "type": "dxRadioGroup"
  },
  {
    "id": "D19b1",
    "text": "Reason",
    "type": "dxTextBox"
  },
]

