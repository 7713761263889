
import React, { useState, useEffect } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import './Lesson2Exercise.scss';
import { tasks as taskList, employees } from './data.js';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';

function getLists(statusArray, taskArray) {
  const tasksMap = taskArray.reduce((result, task) => {
    if (result[task.Task_Status]) {
      result[task.Task_Status].push(task);
    } else {
      result[task.Task_Status] = [task];
    }

    return result;
  }, {});
  return statusArray.map((status) => tasksMap[status]);
}

// function getEmployeesMap(employeesArray) {
//   return employeesArray.reduce((result, employee) => {
//     result[employee.ID] = employee.Name;
//     return result;
//   }, {});
// }

function removeItem(array, removeIdx) {
  return array.filter((_, idx) => idx !== removeIdx);
}

function insertItem(array, item, insertIdx) {
  const newArray = [...array];
  newArray.splice(insertIdx, 0, item);
  return newArray;
}

function reorderItem(array, fromIdx, toIdx) {
  const item = array[fromIdx];
  const result = removeItem(array, fromIdx);
  return insertItem(result, item, toIdx);
}

const taskStatuses = ['Important', 'Average','Not Important'];
// const employeesRecord = getEmployeesMap(employees);

function Card({ task }) {
  const { t } = useTranslation();

  return <div className="card dx-card dx-theme-text-color dx-theme-background-color">
    {/* <div className={`card-priority priority-${task.Task_Priority}`}></div> */}
    <div className="card-subject">{t(task.Task_Subject)}</div>
    {/* <div className="card-assignee">{employeesMap[task.Task_Assigned_Employee_ID]}</div> */}
  </div>;
}

function List({
  title, index, tasks, onTaskDrop,
}) {
  return <div className="list">
    <div className="list-title dx-theme-text-color">{title}</div>
    <ScrollView
      className="scrollable-list"
      direction="vertical"
      showScrollbar="always">
      <Sortable
        className="sortable-cards"
        group="cardsGroup"
        data={index}
        onReorder={onTaskDrop}
        onAdd={onTaskDrop}>
        {tasks.map((task) => <Card
          key={task.Task_ID}
          task={task}>
        </Card>)}
      </Sortable>
    </ScrollView>
  </div>;
}

export default function App() {

  const { t } = useTranslation();

  const [statuses, setStatuses] = React.useState(taskStatuses);
  const [lists, setLists] = React.useState(getLists(taskStatuses, taskList));

  const [tableData, setTableData] = useState([
    { topic: 'What kind of relationship do you want with your family and friends? What kind of person do you want to be in these relationships? How do you want to be with others?', column1: ''},
    { topic: 'What do you think is the most important in health and personal development? What kind of person do you want to be? What qualities do you wish to see in yourself?',column1: ''},
    { topic: 'What meaning do you find in your interests and leisure activities? What is more important to you? What kind of person do you want to be in this area?', column1: ''},
    { topic: 'What kind of employee / colleague do you wish to be? What personal qualities or values do you have? What do you wish to bring to others or to society through your work?', column1: ''},
  ]);

  const { user } = useAuth();
 

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.post(backendlink + '/exercise/getexercise',
          {
            lessonNumber: 2,
            exerciseNumber: 1
          },  
          {
              headers: {
                token: user.token
              }        
          });
          // if (response.data.result !== "No exercise found"){
          //   const data = JSON.parse(response.data.data.content);
          //   var updatedTableData = tableData.map((item, index) => ({
          //     ...item,
          //     column1: data.tableData[index]
          //   }));

            

          //   setTableData(updatedTableData);
          //   setLists(data.lists)
          // }
          if (response.data.errMessage !== "No exercise found"){
            const data = JSON.parse(response.data.content);

            var updatedTableData = tableData.map((item, index) => ({
              ...item,
              column1: data.tableData[index]
            }));

            

            setTableData(updatedTableData);
            setLists(data.lists)
          }

        } catch (error) {
          alert(error);
        }
      };
      
      fetchData();
  }, []);

  const handleCellChange = (rowIndex, colIndex, value) => {
    const newData = [...tableData];
    newData[rowIndex][colIndex] = value;
    setTableData(newData);
  };
  const onListReorder = React.useCallback(({ fromIndex, toIndex }) => {
    setLists((state) => reorderItem(state, fromIndex, toIndex));
    setStatuses((state) => reorderItem(state, fromIndex, toIndex));
  }, []);

  
async function handleSubmission(){

  const tableDataColumns = tableData.map(item => item.column1);

  const data = {
    lists: lists,
    tableData: tableDataColumns
  };

  const jsonData = JSON.stringify(data);

  try {
    const response = await axios.post(backendlink + '/exercise/submitexercise',
    {
      lessonNumber: 2,
      exerciseNumber: 1, 
      content: jsonData
    },  
    {
        headers: {
          token: user.token
        }        
    });
  } catch (error) {
    console.log(error);
    alert(error);
  }
}

  const onTaskDrop = React.useCallback(
    ({
      fromData, toData, fromIndex, toIndex,
    }) => {
      const updatedLists = [...lists];

      const item = updatedLists[fromData][fromIndex];
      updatedLists[fromData] = removeItem(updatedLists[fromData], fromIndex);
      updatedLists[toData] = insertItem(updatedLists[toData], item, toIndex);

      setLists(updatedLists);
    },
    [lists],
  );

  const handleInputChange = (event, rowIndex, columnName) => {
    const newData = [...tableData];
    newData[rowIndex][columnName] = event.target.value;
    setTableData(newData);
  };

  return (
    <React.Fragment>
    <h5>{t("Please consider the four life categories below. What are your values in these areas? There are no right or wrong answers, and you don’t have to worry about what others think. Just complete the figure below according to what you think.")}</h5>
    <div>
    <table className='customTable' style={{ borderCollapse: 'collapse', width: '100%' }}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.topic}>
              <td>{t(row.topic)}</td>
              <td>
                <textarea
                  value={row.column1}
                  onChange={(event) =>
                    handleInputChange(event, rowIndex, 'column1')
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div id="kanban">
      <h5> {t("Please record the values cards you selected in the values card sort exercise, the values you consider to be the most important:")}</h5>
      <ScrollView
        className="scrollable-board"
        direction="horizontal"
        showScrollbar="always">
        <Sortable
          className="sortable-lists"
          itemOrientation="horizontal"
          handle=".list-title"
          onReorder={onListReorder}
          >
          {lists.map((tasks, listIndex) => {
            const status = statuses[listIndex];
            return <List
              key={status}
              title={t(status)}
              index={listIndex}
              tasks={tasks}
              onTaskDrop={onTaskDrop}>
            </List>;
          })}
        </Sortable>
      </ScrollView>
    </div>
    {/* <button onClick={handleSubmission}>Submit</button> */}
    <Button text={t("Submit")} type="default" onClick={handleSubmission}/>

    </React.Fragment>
  );
}

