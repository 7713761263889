import React, { useState, useEffect } from "react";
import { section1, section2, section3, section4, section5, section6 } from './T2questions.js';
import { useTranslation } from 'react-i18next';


import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TextArea from "devextreme-react/text-area";
import RadioGroup from "devextreme-react/radio-group";
import Popup from 'devextreme-react/popup';
import { Button, CheckBox, TextBox } from 'devextreme-react';
import Form, {
  Item,
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  TabbedItem,
  Tab
} from 'devextreme-react/form';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { backendlink } from '../../config';
import { Link, useNavigate } from 'react-router-dom';
import { Group } from "devextreme-react/diagram.js";
import TabPanel from "devextreme/ui/tab_panel";

class ItemComponent extends React.PureComponent {
  render() {
    return <div style={{ fontSize: "20px", fontWeight: "bold" }}>{this.props.flag}</div>;
  }
}


export default function QuestionnaireT2() {


  const { user, updateUserProgress, updateUser } = useAuth();

  const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState({

  });
  const [SectionNumber, setSectionNumber] = useState(1);

  const { t } = useTranslation();

  const submitButtonAttributes = { class: 'submit-button' };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/init/getcontroldata',
          {
            progress: 9
          },
          {
            headers: {
              token: user.token
            }
          });
        if (response.data.errCode == 0) {
          let arr = response.data.data;
          const object = Object.fromEntries(arr.map(item => [Object.keys(item)[0], Object.values(item)[0]]));
          setQuestionnaireAnswer(object)
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };
    if (user.progress >= 9 && user.t2Status == "Incomplete") {
      fetchData();
    }
  }, []);

  // const [employee, setemployee] = useState({
  //     QLQ3: 1,
  //     QLQ4: 4,
  // });

  // const answerArray = [{QLQ3: [{score: 0, text:'Not at all'},{ score:1, text: 'A little'}, {score:2, text:'Some-what'},{score:3, text: 'A lot'}, {score:4, text:'A great deal'}]},
  //  {QLQ4: [{score: 0, text:'Not at all'},{ score:1, text: 'A little'}, {score:2, text:'Some-what'},{score:3, text: 'A lot'}, {score:4, text:'A great deal'}]}]


  const IncreaseSectionNumber = () => {
    setSectionNumber(SectionNumber + 1);

  }

  const DecreaseSectionNumber = () => {
    setSectionNumber(SectionNumber - 1);
  }

  const SaveQuestionnaire = async (e) => {

    // e.preventDefault();

    const transformedState = {
      questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
        questionnumber,
        answer: text,
      }))
    }

    try {

      const result = await axios.post(backendlink + '/init/savecontroldata', {
        // userToken: user.token,
        questionairelist: transformedState.questionairelist,
        progress: 9
      }, {
        headers: {
          token: user.token
        }
      }).then((res) => {


      });

    } catch (error) {
      alert("Error with saving record")
    }

  }

  const SubmitQuestionnaire = async (e) => {

    e.preventDefault();

    if (SectionNumber < 6) {
      setSectionNumber(SectionNumber + 1);
    } else {
      const transformedState = {
        questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
          questionnumber,
          answer: text,
        }))
      };

      try {
        const result = await axios.post(backendlink + '/init/submitcontroldata', {
          userToken: user.token,
          questionairelist: transformedState.questionairelist,
          progress: 9
        }, {
          headers: {
            token: user.token
          }
        }).then((res) => {
          updateUser(res.data.data)
          // navigateToSession1(res.data.data.group);
        });

      } catch (error) {
        alert("Error with submitting record")
      }
    }

  }

  return (
    <React.Fragment>
      <div className={'content-block dx-card responsive-paddings'}>
        {user.t2Status == "Incomplete" &&
          <form onSubmit={SubmitQuestionnaire}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                // Keeps form from submiting on hitting enter
                e.preventDefault();
              }
            }}
          >
            <Form className="radiobuttons" formData={QuestionnaireAnswer} showValidationSummary={true}>

              {SectionNumber === 1 &&
                <GroupItem caption={t('Section 1')}>
                  {section1.map((question, index) => (
                    question.description ? (
                      <Item key={index}>
                        <ItemComponent flag={t(question.description)} />
                      </Item>
                    ) : (
                      <Item key={question.id}
                        editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                        editorOptions={question.type === "dxRadioGroup" ? {
                          items: question.options.map(option => ({
                            ...option,
                            text: t(option.text), // Translate each option text
                          })), layout: "horizontal", valueExpr: 'score'
                        } : {}}
                        dataField={question.id}
                      >
                        <RequiredRule message={`Please answer question ${question.id} in section 1`} />
                        <Label text={question.id + ". " + t(question.text)} />
                      </Item>
                    )
                  ))}
                </GroupItem>
              }
              {SectionNumber == 2 && <GroupItem caption={t('Section 2')}>
                {section2.map((question, index) => (
                  question.description ? (
                    <Item key={index}>
                      <ItemComponent flag={t(question.description)} />
                    </Item>
                  ) : (
                    <Item key={question.id}
                      editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                      editorOptions={question.type === "dxRadioGroup" ? {
                        items: question.options.map(option => ({
                          ...option,
                          text: t(option.text), // Translate each option text
                        })), layout: "horizontal", valueExpr: 'score'
                      } : {}}
                      dataField={question.id}>
                      <RequiredRule message={`Please answer question ${question.id} in section 2`} />
                      <Label text={question.id + ". " + t(question.text)} />
                    </Item>
                  )
                ))}
              </GroupItem>}
              {SectionNumber === 3 &&
                <GroupItem caption={t('Section 3')}>
                  {section3.map((question, index) => (
                    question.description ? (
                      <Item key={index}>
                        <ItemComponent flag={t(question.description)} />
                      </Item>
                    ) : (
                      <Item key={question.id}
                        editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                        editorOptions={question.type === "dxRadioGroup" ? {
                          items: question.options.map(option => ({
                            ...option,
                            text: t(option.text), // Translate each option text
                          })), layout: "horizontal", valueExpr: 'score'
                        } : {}}
                        dataField={question.id}
                      >
                        <RequiredRule message={`Please answer question ${question.id} in section 3`} />
                        <Label text={question.id + ". " + t(question.text)} />
                      </Item>
                    )
                  ))}
                </GroupItem>
              }
              {SectionNumber === 4 &&
                <GroupItem caption={t('Section 4')}>
                  {section4.map((question, index) => (
                    question.description ? (
                      <Item key={index}>
                        <ItemComponent flag={t(question.description)} />
                      </Item>
                    ) : (
                      <Item key={question.id}
                        editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                        editorOptions={question.type === "dxRadioGroup" ? {
                          items: question.options.map(option => ({
                            ...option,
                            text: t(option.text), // Translate each option text
                          })), layout: "horizontal", valueExpr: 'score'
                        } : {}}
                        dataField={question.id}
                      >
                        <RequiredRule message={`Please answer question ${question.id} in section 4`} />
                        <Label text={question.id + ". " + t(question.text)} />
                      </Item>
                    )
                  ))}
                </GroupItem>
              }
              {SectionNumber == 5 && <GroupItem caption={t('Section 5')}>
                {section5.map((question, index) => (
                  question.description ? (
                    <Item key={index}>
                      <ItemComponent flag={t(question.description)} />
                    </Item>
                  ) : (
                    <Item key={question.id}
                      editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                      editorOptions={question.type === "dxRadioGroup" ? {
                        items: question.options.map(option => ({
                          ...option,
                          text: t(option.text), // Translate each option text
                        })), layout: "horizontal", valueExpr: 'score'
                      } : {}}
                      dataField={question.id}>
                      <RequiredRule message={`Please answer question ${question.id} in section 5`} />

                      <Label text={question.id + ". " + t(question.text)} />
                    </Item>)
                ))}
              </GroupItem>}
              {SectionNumber == 6 && <GroupItem caption={t('Section 6')}>
                <Item>
                  <ItemComponent flag={t("Demographics Information")} />
                </Item>
                {section6.map((question, index) => (
                  <Item key={question.id}
                    editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                    editorOptions={question.type === "dxRadioGroup" ? {
                      items: question.options.map(option => ({
                        ...option,
                        text: t(option.text), // Translate each option text
                      })), layout: "horizontal", valueExpr: 'score'
                    } : {}}
                    dataField={question.id}>
                    {/* <RequiredRule message={`Please answer question ${question.id} in section 6`} /> */}
                    {question.id != "D1b" && question.id != "D2b" && question.id != "D5b" && question.id != "D5b1" && question.id != "D6a" && question.id != "D6b" &&
                      question.id != "D6c" && question.id != "D6d" && question.id != "D6c1" && question.id != "D7b" && question.id != "D7c" &&
                      question.id != "D8b" && question.id != "D8bii" &&
                      <RequiredRule message={`Please answer question ${question.id} in section 6`} />}
                    <Label text={question.id + ". " + t(question.text)} />
                  </Item>
                ))}
              </GroupItem>}
              <GroupItem colCount={3}>
                <ButtonItem horizontalAlignment='left'>
                  <ButtonOptions
                    elementAttr={submitButtonAttributes}
                    type={'default'}
                    disabled={SectionNumber == 1}
                    width={"50%"}
                    onClick={DecreaseSectionNumber}
                  >
                    {t("Previous Page")}
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem horizontalAlignment='center'>
                  <ButtonOptions
                    elementAttr={submitButtonAttributes}
                    type={'default'}
                    width={"50%"}
                    onClick={SaveQuestionnaire}
                  >
                    {t("Save")}
                  </ButtonOptions>
                </ButtonItem>
                {SectionNumber == 6 && <ButtonItem horizontalAlignment='right'>
                  <ButtonOptions
                    elementAttr={submitButtonAttributes}
                    type={'default'}
                    width={"50%"}
                    useSubmitBehavior={true}
                  // onClick={SubmitQuestionnaire}
                  >
                    {t("Submit")}
                  </ButtonOptions>
                </ButtonItem>}
                {SectionNumber != 6 && <ButtonItem horizontalAlignment='right'>
                  <ButtonOptions
                    elementAttr={submitButtonAttributes}
                    type={'default'}
                    disabled={SectionNumber == 7}
                    width={"50%"}
                    useSubmitBehavior={true}
                  // onClick={IncreaseSectionNumber}
                  >
                    {t("Next Page")}
                  </ButtonOptions>
                </ButtonItem>}
              </GroupItem>
            </Form>
          </form>
        }
        {user.t2Status != "Incomplete" && <h1>{t("T2 assessment completed, please complete T3 assessment after 6 months")}</h1>
        }
      </div>
    </React.Fragment>
  )
}

