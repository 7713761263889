import React, { useState, useEffect } from "react";
import { section1, section2, section3, section4, section5, section6, section7 } from './T0questions.js';
import { useTranslation } from 'react-i18next';
import './QuestionnaireT0.scss';


import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TextArea from "devextreme-react/text-area";
import RadioGroup from "devextreme-react/radio-group";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import { Button, CheckBox, TextBox } from 'devextreme-react';
import Form, {
    Item,
    SimpleItem,
    GroupItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule,
    TabbedItem,
    Tab,
    Subtitle
} from 'devextreme-react/form';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { backendlink } from '../../config';
import { Link, useNavigate } from 'react-router-dom';
import { Group } from "devextreme-react/diagram.js";
import TabPanel from "devextreme/ui/tab_panel";


class ItemComponent extends React.PureComponent {
    render() {
        return <div style={{ fontSize: "20px", fontWeight: "bold" }}>{this.props.flag}</div>;
    }
}


export default function QuestionnaireT0() {


    const tabPanelOptions = {
        elementAttr: {
            id: "tab-id",
        },
    };

    const navigate = useNavigate();

    const navigateToSession1 = () => {
        setpopupvisible(false);

        if (user.group == "A") {
            navigate('/groupAsession1');

        }
        if (user.group == "B") {
            navigate('/groupBsession1');
        }

    };

    const { user, updateUserProgress, updateUser } = useAuth();

    const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState({

    });
    const [SectionNumber, setSectionNumber] = useState(1);

    const [popupvisible, setpopupvisible] = useState(false);

    const { t } = useTranslation();

    const submitButtonAttributes = { class: 'submit-button' };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + '/init/getcontroldata',
                    {
                        progress: 1
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });
                if (response.data.errCode == 0) {
                    let arr = response.data.data;
                    const object = Object.fromEntries(arr.map(item => [Object.keys(item)[0], Object.values(item)[0]]));
                    setQuestionnaireAnswer(object)
                }

            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        if (user.progress == 1) {
            fetchData();
        }
    }, []);


      const closeButtonOptions = {
        text: t('Close'),
        onClick: navigateToSession1
      };
    // const [employee, setemployee] = useState({
    //     QLQ3: 1,
    //     QLQ4: 4,
    // });

    // const answerArray = [{QLQ3: [{score: 0, text:'Not at all'},{ score:1, text: 'A little'}, {score:2, text:'Some-what'},{score:3, text: 'A lot'}, {score:4, text:'A great deal'}]},
    //  {QLQ4: [{score: 0, text:'Not at all'},{ score:1, text: 'A little'}, {score:2, text:'Some-what'},{score:3, text: 'A lot'}, {score:4, text:'A great deal'}]}]

    const IncreaseSectionNumber = () => {
        setSectionNumber(SectionNumber + 1);
        // const panel = TabPanel.getInstance(document.getElementById("tab-id"));
        // panel.option("selectedIndex", SectionNumber);
        // const panel = TabPanel.getInstance(document.getElementById("tab-id"));
        // if (SectionNumber == 1) {
        //     panel.option("selectedIndex", 1);
        //   } else if (SectionNumber == 2) {
        //     panel.option("selectedIndex", 2);
        //   } else if (SectionNumber == 3) {
        //     panel.option("selectedIndex", 3);
        //   } else if (SectionNumber == 4) {
        //     panel.option("selectedIndex", 4);
        //   }

    }

    const DecreaseSectionNumber = () => {
        setSectionNumber(SectionNumber - 1);
 
    }

 
    const SaveQuestionnaire = async (event) => {

        // event.preventDefault();

        const transformedState = {
            questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
                questionnumber,
                answer: text,
            }))
        }

        try {

            const result = await axios.post(backendlink + '/init/savecontroldata', {
                // userToken: user.token,
                questionairelist: transformedState.questionairelist,
                progress: 1
            }, {
                headers: {
                    token: user.token
                }
            }).then((res) => {
                // console.log("res", res)
                // updateUser(res.data.data)

            });

        } catch (error) {
            alert("Error with saving record")
        }

    }

    const SubmitQuestionnaire = async (e) => {

        e.preventDefault();

        if (SectionNumber < 7) {
            setSectionNumber(SectionNumber + 1);
        } else {
            const transformedState = {
                questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, text]) => ({
                    questionnumber,
                    answer: text,
                }))
            };

            try {
                const result = await axios.post(backendlink + '/init/submitcontroldata', {
                    userToken: user.token,
                    questionairelist: transformedState.questionairelist,
                    progress: 1
                }, {
                    headers: {
                        token: user.token
                    }
                }).then((res) => {
                    if (res.data.errCode === 0) {
                        updateUser(res.data.data);
                        // navigateToSession1(res.data.data.group);
                        setpopupvisible(true);
                    }
                })

            } catch (error) {
                console.log(error)
                alert("Error with submitting record")
            }
        }

    }

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>
                {user.progress == 1 &&
                    <form onSubmit={SubmitQuestionnaire}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                // Keeps form from submiting on hitting enter
                                e.preventDefault();
                            }
                        }}>
                        <Form className="radiobuttons" formData={QuestionnaireAnswer} showValidationSummary={true}>
                            {SectionNumber === 1 &&
                                <GroupItem caption={t('Section 1')}>
                                    {section1.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 1`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }

                            {SectionNumber == 2 && <GroupItem caption={t('Section 2')}>
                                {section2.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            <RequiredRule message={`Please answer question ${question.id} in section 2`} />
                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>
                                    )
                                ))}
                            </GroupItem>}
                            {SectionNumber === 3 &&
                                <GroupItem caption={t('Section 3')}>
                                    {section3.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 3`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }
                            {SectionNumber === 4 &&
                                <GroupItem caption={t('Section 4')}>
                                    {section4.map((question, index) => (
                                        question.description ? (
                                            <Item key={index}>
                                                <ItemComponent flag={t(question.description)} />
                                            </Item>
                                        ) : (
                                            <Item key={question.id}
                                                editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                                editorOptions={question.type === "dxRadioGroup" ? {
                                                    items: question.options.map(option => ({
                                                        ...option,
                                                        text: t(option.text), // Translate each option text
                                                    })), layout: "horizontal", valueExpr: 'score'
                                                } : {}}
                                                dataField={question.id}
                                            >
                                                <RequiredRule message={`Please answer question ${question.id} in section 4`} />
                                                <Label text={question.id + ". " + t(question.text)} />
                                            </Item>
                                        )
                                    ))}
                                </GroupItem>
                            }
                            {SectionNumber == 5 && <GroupItem caption={t('Section 5')}>
                                {section5.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            <RequiredRule message={`Please answer question ${question.id} in section 5`} />

                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>)
                                ))}
                            </GroupItem>}
                            {SectionNumber == 6 && <GroupItem caption={t('Section 6')}>
                                {section6.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            <RequiredRule message={`Please answer question ${question.id} in section 6`} />

                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>)
                                ))}
                            </GroupItem>}
                            {SectionNumber == 7 && <GroupItem caption={t('Section 7')}>
                                <Item>
                                    <ItemComponent flag={t("Demographics Information")} />
                                </Item>
                                {section7.map((question, index) => (
                                    question.description ? (
                                        <Item key={index}>
                                            <ItemComponent flag={t(question.description)} />
                                        </Item>
                                    ) : (
                                        <Item key={question.id}
                                            editorType={question.type === "dxRadioGroup" ? "dxRadioGroup" : "dxTextBox"}
                                            editorOptions={question.type === "dxRadioGroup" ? {
                                                items: question.options.map(option => ({
                                                    ...option,
                                                    text: t(option.text), // Translate each option text
                                                })), layout: "horizontal", valueExpr: 'score'
                                            } : {}}
                                            dataField={question.id}>
                                            {question.id != "D13a" && question.id != "D15a" && question.id != "D15b" && question.id != "D15b1"
                                                && question.id != "D16b" && question.id != "D16b5" && question.id != "D17b" && question.id != "D17b5"
                                                && question.id != "D18b1" && question.id != "D19a1" && question.id != "D19b1"
                                                && question.id != "D18b" && question.id != "D19b" && <RequiredRule message={`Please answer question ${question.id} in section 7`} />}

                                            <Label text={question.id + ". " + t(question.text)} />
                                        </Item>)
                                ))}
                            </GroupItem>}
                            <GroupItem colCount={3}>
                                <ButtonItem horizontalAlignment='left'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        disabled={SectionNumber == 1}
                                        width={"50%"}
                                        onClick={DecreaseSectionNumber}
                                    >
                                        {t("Previous Page")}
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem horizontalAlignment='center'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        width={"50%"}
                                        onClick={SaveQuestionnaire}
                                    >
                                        {t("Save")}
                                    </ButtonOptions>
                                </ButtonItem>
                                {SectionNumber == 7 && <ButtonItem horizontalAlignment='right'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        width={"50%"}
                                        useSubmitBehavior={true}
                                    // onClick={SubmitQuestionnaire}
                                    >
                                        {t("Submit")}
                                    </ButtonOptions>
                                </ButtonItem>}
                                {SectionNumber != 7 && <ButtonItem horizontalAlignment='right'>
                                    <ButtonOptions
                                        elementAttr={submitButtonAttributes}
                                        type={'default'}
                                        disabled={SectionNumber == 7}
                                        width={"50%"}
                                        useSubmitBehavior={true}
                                    // onClick={IncreaseSectionNumber}
                                    >
                                        {t("Next Page")}
                                    </ButtonOptions>
                                </ButtonItem>}
                            </GroupItem>
                        </Form>
                    </form>
                }
                {user.progress > 1 &&
                    <div>

                        <h1>{t("Baseline T0 Questionnaire completed")}</h1>
                        <Popup
                            visible={popupvisible}
                            dragEnabled={true}
                            hideOnOutsideClick={true}
                            // content={popupContent}
                            width={500}
                            height={380}
                            title='Need Help?'
                        >
                            <div>
                                <h6>{t("You have completed T0, please start on Lesson 1")} </h6>
                            </div>

                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                options={closeButtonOptions}
                            />

                        </Popup>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

