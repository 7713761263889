import React, { useState, useEffect } from 'react';

// import 'devextreme/dist/css/dx.light.css';
// import './session1.scss';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useAuth } from '../../contexts/auth';
import YouTube from 'react-youtube';
import axios from 'axios';
import { Lesson2Exercise } from '../../components';
import { backendlink } from '../../config';
import Cookies from 'js-cookie';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';


import Form, {
  Item as FormItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';

export default function Session1() {

  const [videoId, setVideoId] = useState('pm3eW1A4eVM');

  const { user, updateUserProgress } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState({"Have a better understanding of what fear of cancer recurrence is and how it can impact individuals' well-being": false});

  const submitButtonAttributes = { class: 'submit-button' };


  const navigateToGroupAsession2 = () => {
    navigate('/groupAsession2');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/videoexercise/getexercise',
          {
            videoexercisenumber: 1,
          },
          {
            headers: {
              token: user.token
            }
          });

        if (response.data.errMessage !== "No video exercise found") {
          let originalFormat = response.data.data.reduce((obj, item) => {
            obj[item.questionnumber] = item.answer.toLowerCase() === 'true';;
            return obj;
          }, {});
          setQuestionnaireAnswer(originalFormat);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    fetchData();
  }, []);

  async function onVideoEnd() {
    try {
      const result = await axios.post(backendlink + '/lesson/completedvideo', {
        lessonNumber: 2
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode == 0) {
        updateUserProgress(result.data.progress);
        navigateToGroupAsession2();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const opts = {
    height: '390',
    width: '640',
    // playerVars: {
    //   autoplay: 1,
    // },
  };

  const submitQuestionnaire = async (e) => {

    e.preventDefault();
    try {

      let transformedAnswer = Object.entries(QuestionnaireAnswer).map(([questionnumber, answer]) => ({ questionnumber, answer: String(answer) }));


      
      const result = await axios.post(backendlink + '/videoexercise/submitexercise', {
        videoexercisenumber: 1,
        questionlist: transformedAnswer
      }, {
        headers: {
          token: user.token
        }
      });
      if (result.data.errCode === 0) {
        // updateUserProgress(result.data.progress);
        notify(t("Exercise submitted successfully"));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <React.Fragment>
      <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
        <Item title={t("Video")} icon="floppy">
          {videoId && (
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${videoId}`}
              opts={opts}
              controls={true}

              onEnded={onVideoEnd}
            />
          )}
          <form onSubmit={submitQuestionnaire}>

            <Form className="radiobuttons responsive-paddings" formData={QuestionnaireAnswer} showValidationSummary={true}
            >
              <GroupItem caption={t('Please tick the following information/tips that you learned from the video.')}>
                <FormItem editorType="dxCheckBox"
                  dataField="Have a better understanding of what fear of cancer recurrence is and how it can impact individuals' well-being">
                  <Label text={t("Have a better understanding of what fear of cancer recurrence is and how it can impact individuals' well-being")} />
                </FormItem>
              </GroupItem>
              <ButtonItem horizontalAlignment='center'>
                <ButtonOptions
                  elementAttr={submitButtonAttributes}
                  type={'default'}
                  useSubmitBehavior={true}
                  width={"20%"}
                >
                  {t("Submit")}
                </ButtonOptions>
              </ButtonItem>

            </Form>
          </form>
        </Item>
      </TabPanel>
    </React.Fragment>
  )
}