import React, {useCallback} from 'react';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react/button';
import { AuthProvider } from '../../contexts/auth';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { StartingQuestionnaire } from '../../components';
import { backendlink } from '../../config';
import ConsentForm from '../../components/consent-form/ConsentForm';

export default function Foreword() {

  const { user, updateUserProgress } = useAuth();

  const { t } = useTranslation();

    return(
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>

                <h3>{t("Foreword")}</h3>
                <h5>{t("What is Fear of Cancer Recurrence?")}<br></br>{t("Fear of Cancer Recurrence is defined as “fear, worry, or concern relating to the possibility that cancer will come back or progress,” and is one of the most common unmet needs among cancer survivors. Mild or short-term worries about cancer recurrence are a normal and realistic concern, and can lead survivors to engage in helpful self-monitoring and lifestyle behaviours. However, if worries are frequent, severe and uncontrollable, it can have a devastating effect on quality of life, leading to functional disability and adverse effects on emotional health.")}</h5>
                <h5>{t("According to research conducted in our own centre, approximately 37% of cancer patients in Hong Kong will struggle with fears of cancer recurrence shortly after treatment completion and experience undue emotional stress. Due to excessive worrying, these patients may engage in overly frequent bodily self-monitoring or often seek out reassurance from medical professionals, avoid cancer-related news, experience intrusive thoughts and images about cancer recurrence and may experience difficulty planning for the future.")}</h5>
                <h5>{t("About this study")}<br></br>{t("The School of Public Health of the University of Hong Kong and the University of Hong Kong Jockey Club Institute of Cancer Care (JCICC) are currently conducting a study titled, ‘Conquering fear online: a pilot randomised controlled trial assessing the efficacy and feasibility of an internet-based self-management intervention on subclinical fear of cancer recurrence ’. The goal of this study is to assess the effectiveness of the online self-management based intervention on cancer survivors experiencing fears of cancer recurrence. We hope that through this 6-session online program, cancer survivors can reduce concerns about their future, no longer struggle with fears of cancer recurrence, improve their quality of life and appreciate anew a full and happy life.")}</h5>
                <h5>{t("Note to participants")}<br></br>{t("In order to ensure that participants grasp the relevant techniques and to ensure adherence to the study protocol and maintenance of study integrity, we encourage patients to complete all 6 sessions of the program, and to practice all the techniques in between sessions. After the 6 sessions, participants will be invited to complete follow-up assessments.")}</h5>
                <h5>{t("Please be aware that participation is entirely voluntary. You have the right to refuse or withdraw at any time and your care will remain the same. In addition, you have the rights of access to personal data and publicly available study results, if and when needed. Under the laws of Hong Kong (in particular the Personal Data (Privacy) Ordinance, Cap 486), you enjoy or may enjoy rights for the protection of the confidentiality of your personal data, such as those regarding the collection, custody, retention, management, control, use (including analysis or comparison), transfer in and out of Hong Kong, non-disclosure, erasure and/or in any way dealing with or disposing of any of your personal data in or for this study. For any query, you should consult the Privacy Commissioner for Personal Data or their office (telephone no.: 2827 2827) as to the proper monitoring or supervision of your personal data protection so that your full awareness and understanding of the significance of compliance with the law governing privacy data is assured.")}</h5>
                
            </div>
        </React.Fragment>
    )

}