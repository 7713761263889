import React, { useState} from 'react';
import Form, {
    Item, 
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import './StartingQuestionnaire.scss';
import { backendlink } from '../../config';
import { Link, useNavigate } from 'react-router-dom';
import { Popup, ToolbarItem } from 'devextreme-react/popup';


class ItemComponent extends React.PureComponent {
    render() {
      return <div style={{ fontSize: "20px", fontWeight:"bold" }}>{this.props.flag}</div>;
    }
  }

export default function PatientInformationForm(){

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { user, updateUser } = useAuth();

    const [popupvisible, setpopupvisible] = useState(false);

    const navigateToT0Questionnaire = () => {
        setpopupvisible(false);

        if (user.group != "X"){
           navigate('/questionnaireT0');      
        }

      };

    const submitButtonAttributes = { class: 'submit-button' };

    const [QuestionnaireAnswer, setQuestionnaireAnswer] = useState( {
 
        });    

        const closeButtonOptions = {
            text: t('Close'),
            onClick: navigateToT0Questionnaire
          };

    const submitQuestionnaire = async (e) => {

        e.preventDefault();
        const transformedState = {
            questionairelist: Object.entries(QuestionnaireAnswer).map(([questionnumber, {score, text}]) => ({
              questionnumber,
              answer: text,
              score
            }))
          };
          
        try{
            const result = await axios.post(backendlink + '/init/initquestionaire',{
                questionairelist: transformedState.questionairelist
            },{
            headers: {
                token: user.token
            }
            }).then((res)=>{
                if (res.data.errCode === 0) {
                    updateUser(res.data.data);
                    setpopupvisible(true);
                    // navigateToT0Questionnaire(res.data.data.group);
                }
            });
          } catch (error) {
            console.log("error", error);
          }
      };

    return(
        <React.Fragment>
            <form onSubmit={submitQuestionnaire}>
            <Form className="radiobuttons" formData={QuestionnaireAnswer} showValidationSummary={true}
>
                <GroupItem  caption={t('Most people who have been diagnosed with cancer are worried, to varying degrees, that there might be a recurrence of the cancer. By recurrence, we mean the possibility that the cancer could return or progress in the same place or in another part of the body. This questionnaire aims to better understand the experience of worries about cancer recurrence. Please read each statement and indicate to what degree it applied to you DURING THE PAST MONTH by choosing the appropriate response.')}>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Not at all')},{ score:1, text:t( 'A little')}, {score:2, text:t('Some-what')},{score:3, text:t( 'A lot')}, {score:4, text:t('A great deal')}], layout:"horizontal" }}
                            dataField='FRCI9'>
                            <RequiredRule message='Please answer Q1' />
                            <Label text={t("I am worried or anxious about the possibility of cancer recurrence")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Not at all')},{ score:1, text:t( 'A little')}, {score:2, text:t('Some-what')},{score:3, text:t( 'A lot')}, {score:4, text:t('A great deal')}], layout:"horizontal" }}
                            dataField='FRCI10'>
                            <RequiredRule message='Please answer Q2' />   
                            <Label text={t("I am afraid of cancer recurrence")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Not at all')},{ score:1, text:t( 'A little')}, {score:2, text:t('Some-what')},{score:3, text:t( 'A lot')}, {score:4, text:t('A great deal')}], layout:"horizontal" }}
                            dataField='FRCI11'>
                            <RequiredRule message='Please answer Q3' />
                            <Label text={t("I believe it is normal to be worried or anxious about the possibility of cancer recurrence")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Not at all')},{ score:1, text:t( 'A little')}, {score:2, text:t('Some-what')},{score:3, text:t( 'A lot')}, {score:4, text:t('A great deal')}], layout:"horizontal" }}
                            dataField='FRCI12'>
                            <RequiredRule message='Please answer Q4' />
                            <Label text={t("When I think about the possibility of cancer recurrence, this triggers other unpleasant thoughts or images (such as death, suffering, the consequences for my family)")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 4, text:t('Not at all')},{ score:3, text:t( 'A little')}, {score:2, text:t('Some-what')},{score:1, text:t( 'A lot')}, {score:0, text:t('A great deal')}], layout:"horizontal" }}
                            dataField='FRCI13'>
                            <RequiredRule message='Please answer Q5' />
                            <Label text={t("I believe that I am cured and that the cancer will not come back")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Not at all at risk')},{ score:1, text:t( 'A little at risk')}, {score:2, text:t('Some-what at risk')},{score:3, text:t( 'A lot at risk')}, {score:4, text:t('A great deal at risk')}], layout:"horizontal" }}
                            dataField='FRCI14'>
                            <RequiredRule message='Please answer Q6' />
                            <Label text={t("In your opinion, are you at risk of having a cancer recurrence?")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('Never')},{ score:1, text:t( 'A few times a month')}, {score:2, text:t('A few times a week')},{score:3, text:t( 'A few times a day')}, {score:4, text:t('Several times a day')}], layout:"horizontal" }}
                            dataField='FRCI15'>
                            <RequiredRule message='Please answer Q7' />
                            <Label text={t("How often do you think about the possibility of cancer recurrence?")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('I don’t think about it')},{ score:1, text:t( 'A few seconds')}, {score:2, text:t('A few minutes')},{score:3, text:t( 'A few hours')}, {score:4, text:t('Several hours')}], layout:"horizontal" }}
                            dataField='FRCI16'>
                            <RequiredRule message='Please answer Q8' />
                            <Label text={t("How much time per day do you spend thinking about the possibility of cancer recurrence?")}/>
                        </Item>
                        <Item editorType="dxRadioGroup"
                            editorOptions={{items:[{score: 0, text:t('I don’t think about it')},{ score:1, text:t( 'A few weeks')}, {score:2, text:t('A few months')},{score:3, text:t( 'A few years')}, {score:4, text:t('Several years')}], layout:"horizontal" }}
                            dataField='FRCI17'>
                            <RequiredRule message='Please answer Q9' />
                            <Label text={t("How long have you been thinking about the possibility of cancer recurrence?")}/>
                        </Item>

                </GroupItem>
                <ButtonItem horizontalAlignment='center'>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        type={'default' }
                        // disabled={!isFormFilled()}
                        useSubmitBehavior={true}
                        width={"20%"}
                    >
                        {t("Submit")}
                    </ButtonOptions>
                </ButtonItem>
            </Form>
            </form>
            <Popup
                visible={popupvisible}
                dragEnabled={true}
                hideOnOutsideClick={true}
                // content={popupContent}
                width={500}
                height={380}
                title='Need Help?'
            >
                <div>
                    <h6>{t("You have completed the starting questionnaire, please start on Lesson 1")} </h6>
                </div>

                {/* <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                /> */}

            </Popup>
        </React.Fragment>
    )
}

