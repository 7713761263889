export const navigation = [
  {
    text: 'Screening Assessment',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Foreword',
    path: '/foreword',
    icon: 'floppy'
  },
  {
    text: 'Enquiry',
    path: '/enquiry',
    icon: 'floppy'
  },
];
